import { DemoProjectState } from '.';

export const initialState: DemoProjectState = {
  list: [],
  projectDetails: {
    _id: '',
    description: '',
    projectThumbnailPath: '',
    title: '',
    fileId: '',
  },
  loading: false,
  uploadQueue: [],
  createFileData: {
    projectId: '',
    fileId: '',
    fileName: '',
    filePath: '',
    fileSize: '',
    isFolder: false,
    folderId: '',
  },
  createFolder: {
    projectId: '',
    fileId: '',
    name: '',
    color: '',
    isPrivate: false,
  },
};
