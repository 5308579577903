'use client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { set } from 'lodash';
import { DashboardInterface, DateFilterTypeEnum, ResponseData } from './types';

export const useClinicSlice = createSlice({
  name: 'DashboardState',
  initialState,
  reducers: {
    setEndDate: (state, action: PayloadAction<Date | string>) => {
      state.endDate = action.payload;
    },
    setFilterValue: (state, action: PayloadAction<DateFilterTypeEnum>) => {
      state.filterValue = action.payload;
    },
    setStartDate: (state, action: PayloadAction<Date | string>) => {
      state.startDate = action.payload;
    },
    setChartData: (state, action: PayloadAction<ResponseData>) => {
      state.chartData = action.payload;
    },
    setDashboardsList: (state, action: PayloadAction<DashboardInterface>) => {
      state.DashboardFormData = action.payload;
    },
    doGetDashboardList: (state) => {},
    getDashboardChartData: (state) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
