import { useEffect, useState } from 'react';
import { DragEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import mime from 'mime';
import { Box } from '@chakra-ui/react';
import { FileStatusEnum, FileTypeEnum, UploadStatusEnum } from './Redux/types';
import { actions } from './Redux/slice';
import { selectProjectDetails } from './Redux/selector';
import FileSizeExtension from 'components/hooks/FileSizeExtension';

function DragAndDropBox({ children }: any) {
  let data: any[] = [];
  let fileData: any[] = [];
  const dispatch = useDispatch();
  const params = useParams();
  const projectDetails = useSelector(selectProjectDetails);
  const [dragIsOver, setDragIsOver] = useState(false);
  async function sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  // Define the event handlers
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragIsOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragIsOver(false);
  };
  const traverseFileTree = (item: any, path: any) => {
    path = path || '';
    if (item.isFile) {
      // Get file
      item.file((file: any) => {
        const element = path + file.name;
        const element2 = file;
        element2['mimeType'] = mime.getType(element);
        // if (fileSizeExtension(file, 'dashboard')) {
        data.push(element);
        fileData.push(element2);
        // }
        console.log('File:', path + file.name);
      });
    } else if (item.isDirectory) {
      // Get folder contents
      var dirReader = item.createReader();
      dirReader.readEntries((entries: any) => {
        for (var i = 0; i < entries.length; i++) {
          traverseFileTree(entries[i], path + item.name + '/');
        }
      });
    }
  };
  const onDrop = async (event: any) => {
    data = [];
    fileData = [];
    event.stopPropagation();
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (event.dataTransfer.items) {
      var items = event.dataTransfer.items;
      for (var i = 0; i < items.length; i++) {
        // webkitGetAsEntry is where the magic happens
        var item = items[i].webkitGetAsEntry();
        if (item) {
          traverseFileTree(item, '');
        }
      }
      await sleep(1000);
    } else {
      // Use DataTransfer interface to access the file(s)
      if (files && files?.length > 0) {
        for (let index = 0; index < files?.length; index++) {
          const element = files[index].name;
          const element2 = files[index];
          element2['mimeType'] = mime.getType(element);
          data.push(element);
          fileData.push(element2);
        }
      }
    }
    console.log('data', data, fileData);
    if (data.length > 0) {
      dispatch(
        actions.doAddFile({
          fileName: data,
          projectId: projectDetails?._id,
          fileId: params.fid,
          callback: (responseData) => {
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              const fileDataItem = responseData[element];
              dispatch(
                actions.doUploadFileFolder({
                  data: files[index],
                  signedUrl: fileDataItem.signedUrl,
                  result: mime.getType(files[index].name),
                  callback: () => {
                    dispatch(
                      actions.doCreateFileFolder({
                        data: {
                          fileId: fileDataItem.fileId,
                          fileName: fileDataItem.fileName,
                          filePath: fileDataItem.fileNameWithPrefix,
                          projectId: fileDataItem.projectId,
                          fileSize: files[index].size,
                          isFolder: true,
                          folderId: params.fid,
                        },
                        callback() {
                          dispatch(
                            actions.doGetFolderFileList({
                              id: params.fid,
                              callback() {},
                            }),
                          );
                        },
                      }),
                    );
                  },
                }),
              );
            }
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (window?.location?.pathname?.includes('dashboard')) {
      const handleDragStart = (event: DragEvent) => {
        event.preventDefault();
      }; //@ts-ignore
      window.addEventListener('dragstart', handleDragStart);
      return () => {
        //@ts-ignore
        window.removeEventListener('dragstart', handleDragStart);
      };
    }

    // Cleanup the event listener on component unmount
  }, [window?.location?.pathname?.includes('dashboard')]);
  return (
    <div>
      <Box
        //@ts-ignore
        sx={{
          overflow: 'auto',
          ' .scrollBox': {
            '::-webkit-scrollbar': {
              display: 'none !important',
            },
          },
          // background: dragIsOver ? "red" : "white",
        }}
        draggable="true"
        id="dropzone"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={onDrop}
      >
        {children}
      </Box>
      {/* <ProgressModal name={"progress"} data={data} /> */}
    </div>
  );
}

export default DragAndDropBox;
