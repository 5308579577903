import { StatusTypeEnum } from '../../../Redux/User/types';
import { TeamState } from '.';

export const initialState: TeamState = {
  loading: false,
  editLoading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  list: [],
  form: {
    _id: '',
    name: '',
    password: '',
    email: '',
    ownerName: '',
    totalProjects: 1,
    totalUsers: 1,
    isActive: StatusTypeEnum.ACTIVE,
    actions: false,
    profilePic: '',
  },
  sortColumn: '',
  sortDir: '',
  teamUpdate: false,
  buttonLoading: false,
  disableAccount: false,
  deleteAccount: false,
};
