import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import {
  CreateFileInterface,
  CreateVersionInterface,
  DataInterface,
  IProjectDetails,
} from './types';
import { set } from 'lodash';
export const useClinicSlice = createSlice({
  name: 'demoProject',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    doGetDemoProjectDetails: (state) => {},
    setProjectDetails: (state, action: PayloadAction<IProjectDetails>) => {
      state.projectDetails = action.payload;
    },

    doGetFolderFileList: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {},
    setFileFolderList: (state, action: PayloadAction<Array<DataInterface>>) => {
      state.list = action.payload;
    },
    doAddFile: (
      state,
      action: PayloadAction<{
        fileName: any;
        projectId: string;
        fileId: string;
        callback: (responseData: any) => void;
      }>,
    ) => {},

    doUploadFileFolder: (
      state,
      action: PayloadAction<{
        data: any;
        signedUrl: string;
        result: any;
        callback: () => void;
      }>,
    ) => {},
    setCreateVersionData: (
      state,
      action: PayloadAction<CreateFileInterface>,
    ) => {
      state.createFileData = action.payload;
    },

    doCreateFileFolder: (
      state,
      action: PayloadAction<{
        data: CreateFileInterface;
        callback: () => void;
      }>,
    ) => {},

    // folder create
    clearFrom: (state) => {
      state.createFolder.name = '';
      state.createFolder.color = '';
      state.createFolder.isPrivate = false;
      state.createFolder.fileId = '';
      state.createFolder.projectId = '';
    },
    updateFolderFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>,
    ) => {
      set(state, `createFolder.${action.payload.key}`, action.payload.value);
    },
    doCreateFolder: (
      state,
      action: PayloadAction<{ callback: () => void }>,
    ) => {},
    doDeleteFileAndFolder: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
