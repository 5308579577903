import { toast } from 'sonner';
import errorCode from '../ErrorCode/Index';

export default function CatchBlockFunction(error: any) {
  if (errorCode(error?.response?.data.statusCode)) {
    toast.error(error?.response?.data.message);
    return;
  }
  if (error.message !== 'e is undefined') {
    if (
      error.message !== "undefined is not an object (evaluating 'e.context')"
    ) {
      if (
        error.message !==
        "Cannot read properties of undefined (reading 'context')"
      ) {
        if (
          error.message !== "Cannot read properties of undefined (reading '0')"
        ) {
          if (error.message !== 'call: argument fn is undefined or null') {
            if (
              error.message !==
              "Cannot read properties of undefined (reading 'results')"
            ) {
              if (error.response) {
                if (error.response.status !== 401) {
                  if (error.response.data.errors) {
                    let dataerrer = error.response.data.errors.map(
                      (item: any) => {
                        return {
                          name: item.property,
                          message: item.constraints.matches,
                        };
                      },
                    );
                    for (let index = 0; index < dataerrer.length; index++) {
                      const element = dataerrer[index];

                      toast.error(element.message);
                    }
                    let dataerrer1 = error.response.data.errors.map(
                      (item: any) => {
                        return {
                          name: item.property,
                          message: item.constraints.isEmail,
                        };
                      },
                    );
                    for (let index = 0; index < dataerrer1.length; index++) {
                      const element = dataerrer1[index];
                      toast.error(element.message?.data.message);
                    }
                    let dataerrer2 = error.response.data.errors.map(
                      (item: any) => {
                        return {
                          name: item.property,
                          message: item.constraints.isString,
                        };
                      },
                    );
                    for (let index = 0; index < dataerrer2.length; index++) {
                      const element = dataerrer2[index];

                      toast.error(element.message);
                    }
                    let dataerrer3 = error.response.data.errors.map(
                      (item: any) => {
                        return {
                          name: item.property,
                          message: item.constraints.isNotEmpty,
                        };
                      },
                    );
                    for (let index = 0; index < dataerrer2.length; index++) {
                      const element = dataerrer3[index];
                      toast.error(element.message);
                    }
                  } else {
                    toast.error(error.response.data.message);
                  }
                } else {
                  localStorage.removeItem('token');
                }
              } else {
                toast.error(error.message);
              }
            }
          }
        }
      }
    }
  }
}
