'use client';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { UpdatePassword, loginRequest, meProfileRequest } from '../../utils/request';
import { selectForm, selectPassword } from './selector';
import { LoginInterface, UpdatePasswordInterface } from './types';
import CatchBlockFunction from 'components/hooks/CatchError';
import { toast } from 'sonner';

export function* doLoginRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: LoginInterface = yield select(selectForm);

  if (form.email.length == 0) {
    toast.error("Please enter email");
    return;
  }
  if (form.password.length == 0) {
    toast.error("Please enter password");
    return;
  }
  let data = {
    email: form.email,
    password: btoa(form.password),
  };

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(loginRequest, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    toast.success(response.data.message);
    yield put(actions.setIsLogin(true));
    yield put(actions.setToken(response.data.data.accessToken));
    yield call(action?.payload?.callback(response.data.data.accessToken));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetMeProfileRequest(action: {
  payload: { token: string; callback: any };
}) {
  yield delay(500);
  if (action.payload.token) {
    try {
      yield put(actions.setLoading(true));
      const response: AxiosResponse = yield call(meProfileRequest);
      if (response && !response.data) {
        toast.error(response.data.message);
        return;
      }
      yield put(
        actions.setProfileData({
          name: response.data.data.name,
          email: response.data.data.email,
          _id: response.data.data._id,
        }),
      );
      yield put(actions.setIsLogin(true));
      yield put(actions.setLoading(true));
      yield call(
        action?.payload?.callback({
          role: response.data.role,
          id: response.data.id,
        }),
      );
    } catch (error: any) {
      yield put(actions.setLoading(false));
      CatchBlockFunction(error);
    }
  } else {
    yield put(actions.setLoading(false));
    yield put(actions.setIsLogin(false));
  }
}

export function* doUpdatePasswordRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  
  const form: UpdatePasswordInterface = yield select(selectPassword);
  let data = {
    password: btoa(form.password),
    confirmPassword: btoa(form.confirmPassword),
  };
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UpdatePassword, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    toast.success(response.data.message);
    yield put(actions.setToken(response.data.data.accessToken));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* authRepoSaga() {
  yield takeLatest(actions.doLogin, doLoginRequest);
  yield takeLatest(actions.doGetProfile, doGetMeProfileRequest);
  yield takeLatest(actions.doUpdatePassword, doUpdatePasswordRequest);
}
