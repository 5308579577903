import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  selectButtonLoading,
  selectEditLoading,
  selectForm,
  selectTeamUpdate,
} from '../../Redux/Team/selector';
import { actions } from '../../Redux/Team/slice';
import FooterButtons from 'components/FooterButtons/Index';
import ModalTemplate from 'components/ModalTemplate/Index';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusTypeEnum } from '../../Redux/User/types';
import { toast } from 'sonner';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface props {
  handleSubmit: any;
}

function Add(props: props) {
  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const form = useSelector(selectForm);
  const buttonLoading = useSelector(selectButtonLoading);
  const loading = useSelector(selectEditLoading);
  const show = useSelector(selectTeamUpdate);
  const dispatch = useDispatch();

  const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };
  const handleFieldTeamChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    if (value.length <= 30) {
         dispatch(actions.updateFormValue({ key: name, value: value })); 
    }else{
      toast.error("More Then 30 Not Accepted")
      return
    }

  };
  const handleSelectChange = (name :string , evt: any) => {
    dispatch(actions.updateFormValue({ key: name, value: evt }));
  };
  const handleClose = () => {
    dispatch(actions.toggleTeamUpdate(false));
  };

  useEffect(() => {
    dispatch(actions.setEditLoading(true));
    return () => {};
  }, []);

  return (
    <>
      <ModalTemplate
        title={'Team Details'}
        open={show}
        handleClose={handleClose}
      >
        {loading ? (
          <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
            <Spinner color="brand.500" />
          </Box>
        ) : (
          <Box>
            <FormControl>
              <Grid rowGap={'20px'}>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Team Name
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="text"
                    placeholder="Enter name"
                    size="md"
                    name="name"
                    value={form.name}
                    onChange={handleFieldTeamChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Owner Name
                  </FormLabel>
                  <Input
                    disabled={true}
                    isRequired={true}
                    bgColor={form?._id ? '#313248' : '#1B1C20'}
                    variant="auth"
                    fontSize="15"
                    type="text"
                    placeholder="Enter email address"
                    size="md"
                    name="ownerName"
                    value={form.ownerName}
                    onChange={handleFieldChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Status
                  </FormLabel>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      _hover={{ borderColor: '#313248' }}
                      _focus={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      _active={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      variant="auth"
                      size="md"
                      textAlign={'start'}
                      fontSize="15"
                      value={form?.isActive}
                      width={'100%'}
                      borderColor="#313248"
                      backgroundColor="#1B1C20"
                      borderRadius="4px"
                      color={textColor}
                    >
                      {statusData.find((data) => data.id === form?.isActive)
                        ?.item || 'Select an option'}
                    </MenuButton>
                    <MenuList backgroundColor="#1B1C20" borderColor="#313248" width={"100%"} padding={0}>
                      {statusData.map((data) => (
                        <MenuItem
                          key={data.id}
                          width={'100%'}
                          style={{
                            width: '100%',
                          }}
                          value={data.id}
                          onClick={() => handleSelectChange("isActive" , data.id)}
                          _hover={{
                            backgroundColor: '#313248',
                            color: '#D2D3E0',
                          }}
                          backgroundColor="#21232E"
                          color="#D2D3E0"
                        >
                          {data.item}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </GridItem>
              </Grid>
            </FormControl>
          </Box>
        )}
        <FooterButtons
          title={'Save Changes'}
          loading={buttonLoading}
          handleCancelButton={handleClose}
          handleClick={props?.handleSubmit}
        />
      </ModalTemplate>
    </>
  );
}

export default Add;
const statusData = [
  {
    id: StatusTypeEnum.ACTIVE,
    item: `Active`,
  },
  {
    id: StatusTypeEnum.INACTIVE,
    item: `In Active`,
  },
];

const optionsStyle = {
  backgroundColor: '#21232E',
  color: '#D2D3E0',
  '.options: hover': {
    backgroundColor: 'rgb(49, 50, 72) !important',
  },
  '.options:placeholder': {
    backgroundColor: '#21232E',
    color: '#D2D3E0',
  },
};
