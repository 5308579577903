import {
  Box,
  CloseButton,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Spinner,
  Table,
  Td,
  Text,
  Th,
  Tr,
  Icon,
  Flex,
  useColorModeValue,
  Button,
  Image,
} from '@chakra-ui/react';
import Circle from '@uiw/react-color-circle';
import { selectTogglePassword } from '../../Redux/Login/selector';
import {
  selectButtonLoading,
  selectDraftForm,
  selectEditLoading,
} from '../../Redux/Plan/selector';
import { toast } from 'sonner';
import { MdUpload } from 'react-icons/md';
import { actions } from '../../Redux/Plan/slice';
import FooterButtons from 'components/OnlyOneButton/Index';
import ModalTemplate from 'components/ModalTemplate/Index';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { typeEnum } from '../../Redux/Plan/types';
import mime from 'mime';
import { useDropzone } from 'react-dropzone';
import { path } from 'utils/Api';

interface props {
  id: string;
  open: boolean;
  handleClose: any;
}

function DraftAdd(props: props) {
  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const form = useSelector(selectDraftForm);
  const buttonLoading = useSelector(selectButtonLoading);
  const loading = useSelector(selectEditLoading);
  const brandColor = useColorModeValue('brand.500', 'white');
  const dispatch = useDispatch();

  const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateDraftFormValue({ key: name, value: value }));
  };
  const handleSelectChange = (name: string, evt: any) => {
    dispatch(
      actions.updateDraftFormValue({
        key: name,
        value: evt.target.value,
      }),
    );
    dispatch(
      actions.updateDraftFormValue({
        key: 'fileDataForm.type',
        value: evt.target.value,
      }),
    );
  };
  const handleSelectPrivateChange = (name: string, evt: any) => {
    dispatch(
      actions.updateDraftFormValue({
        key: name,
        value: Number(evt.target.value),
      }),
    );
  };
  const nameRegex = /^[A-Za-z]+([ '-][A-Za-z]+)*$/;
  const handleAdd = () => {
    if (form?.title.trim().length == 0) {
      toast.error('Please enter name');
      return;
    }
    dispatch(
      actions.doUpdateDemo({
        callback: () => {
          props?.handleClose();
          dispatch(actions.doGetDemoProjectList());
          dispatch(actions.doGetList());
        },
      }),
    );
  };
  const handleDelete = (index: number) => {
    dispatch(
      actions.removeDraftForm({
        index: index,
      }),
    );
  };
  const HandleAdd = () => {
    if (form.type == typeEnum.FILE) {
      if (form.fileDataForm.fileName.length == 0) {
        toast.error('Please Upload Image');
        return;
      }
      if (form.fileDataForm.imagePath.length == 0) {
        toast.error('Please Upload Image');
        return;
      }
    }
    if (form.type == typeEnum.FOLDER) {
      if (form.fileDataForm.fileName.length == 0) {
        toast.error('Please write folder name');
        return;
      }
      if (form.fileDataForm.folderColor.length == 0) {
        toast.error('Please Pick Folder Color');
        return;
      }
    }
    dispatch(
      actions.updateDraftFormValue({
        key: 'fileDataForm.isNew',
        value: true,
      }),
    );
    dispatch(actions.addDraftForm());
    dispatch(actions.ClearDraftForm());
    dispatch(
      actions.updateDraftFormValue({
        key: 'fileDataForm.type',
        value: typeEnum.ALL,
      }),
    );

    dispatch(
      actions.updateDraftFormValue({
        key: 'type',
        value: typeEnum.ALL,
      }),
    );
  };

  const colors = [
    '#EEEFFC',
    '#AAC9F1',
    '#8C2D2D',
    '#307DDE',
    '#1199B1',
    '#AD90F9',
    '#FF3B2E',
    '#FFBFA8',
    '#FEE581',
    '#495D54',
    '#96D8BB',
    '#FF6537',
    '#FFA149',
    '#E9D6D9',
    '#C79BA2',
    '#4631D1',
    '#D13186',
    '#009E5F',
    '#009E5E',
    '#009E5C',
    '#136B46',
  ];
  const [loader, setLoader] = useState(false);
  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setLoader(true);
      dispatch(
        actions.addImage({
          data: acceptedFiles[0].name,
          type: 1,
          callback: (signedUrl, fileNameWithPrefix) => {
            dispatch(
              actions.UploadeImage({
                data: acceptedFiles[0],
                signedUrl: signedUrl,
                result: mime.getType(acceptedFiles[0].name),
                callback: () => {
                  setLoader(false);
                  dispatch(
                    actions.updateDraftFormValue({
                      key: 'fileDataForm.imagePath',
                      value: fileNameWithPrefix,
                    }),
                  );
                  dispatch(
                    actions.updateDraftFormValue({
                      key: 'fileDataForm.imageSize',
                      value: acceptedFiles[0].size,
                    }),
                  );
                  dispatch(
                    actions.updateDraftFormValue({
                      key: 'fileDataForm.fileName',
                      value: acceptedFiles[0].name,
                    }),
                  );
                },
              }),
            );
          },
        }),
      );
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      'image/png, image/jpeg, video/mp4, video/mpeg, video/quicktime, video/x-msvideo, video/x-ms-wmv',
  });

  const handleColorChange = (color: string) => {
    dispatch(
      actions.updateDraftFormValue({
        key: 'fileDataForm.folderColor',
        value: color,
      }),
    );
  };

  const determineFileType = (path: any) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv'];

    const extension = path.split('.').pop().toLowerCase();

    if (imageExtensions.includes(extension)) {
      return 'image';
    }
    if (videoExtensions.includes(extension)) {
      return 'video';
    }
    return 'unknown';
  };
  const fileType = determineFileType(form.fileDataForm.imagePath);

  return (
    <>
      {loading ? (
        <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
          <Spinner color="brand.500" />
        </Box>
      ) : (
        <Box backgroundColor={"transparent"}>
          <FormControl>
            <Grid
              templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
              gap={2}
            >
              <GridItem>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Title<Text color={brandStars}> *</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="15"
                  type="text"
                  placeholder="Enter title"
                  size="md"
                  name="title"
                  value={form?.title}
                  maxLength={255}
                  onChange={handleFieldChange}
                />
              </GridItem>
              <GridItem>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Description<Text color={brandStars}> *</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="15"
                  type="text"
                  placeholder="Enter Description"
                  size="md"
                  name="description"
                  value={form?.description}
                  onChange={handleFieldChange}
                />
              </GridItem>
              <GridItem>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Select Upload Type<Text color={brandStars}>*</Text>
                </FormLabel>
                <Select
                  _hover={{
                    borderColor: '#313248',
                  }}
                  _focus={{
                    borderColor: '#313248',
                    outline: 'none',
                    boxShadow: 'none',
                  }}
                  _active={{
                    borderColor: '#313248',
                    outline: 'none',
                    boxShadow: 'none',
                  }}
                  variant="outline"
                  size="md"
                  fontSize="15"
                  borderColor={'#313248'}
                  backgroundColor={'#1B1C20'}
                  borderRadius={'4px'}
                  name="plan"
                  color={textColor}
                  value={form?.type}
                  sx={{
                    '> option': {
                      background: '#21232E',
                      color: '#D2D3E0',
                    },
                  }}
                  onChange={(e) => handleSelectChange('type', e)}
                >
                  {planData.map((data: any) => (
                    <option key={data?.id} value={data?.id}>
                      {data?.item}
                    </option>
                  ))}
                </Select>
              </GridItem>
              {form?.type == typeEnum.FOLDER && (
                <>
                  <GridItem>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      FileName<Text color={brandStars}> *</Text>
                    </FormLabel>
                    <Input
                      isRequired={true}
                      variant="auth"
                      fontSize="15"
                      type="text"
                      placeholder="Enter FileName"
                      size="md"
                      name="fileDataForm.fileName"
                      value={form?.fileDataForm?.fileName}
                      maxLength={255}
                      onChange={handleFieldChange}
                    />
                  </GridItem>
                  <GridItem display={'block'} justifyContent={'center'}>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                    >
                      Folder Color<Text color={brandStars}> *</Text>
                    </FormLabel>
                    <Box width={'70%'}>
                      <Circle
                        colors={colors}
                        color={form?.fileDataForm.folderColor}
                        className="circleContainer"
                        onChange={(color) => {
                          handleColorChange(color.hex);
                        }}
                      />
                    </Box>
                  </GridItem>
                  <GridItem>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Is Private<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Select
                      _hover={{
                        borderColor: '#313248',
                      }}
                      _focus={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      _active={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      variant="outline"
                      size="md"
                      fontSize="15"
                      borderColor={'#313248'}
                      backgroundColor={'#1B1C20'}
                      borderRadius={'4px'}
                      name="plan"
                      color={textColor}
                      value={Number(form?.fileDataForm?.isPrivate)}
                      sx={{
                        '> option': {
                          background: '#21232E',
                          color: '#D2D3E0',
                        },
                      }}
                      onChange={(e) =>
                        handleSelectPrivateChange('fileDataForm.isPrivate', e)
                      }
                    >
                      {IsPrivate.map((data: any) => (
                        <option key={data?.id} value={data?.id}>
                          {data?.item}
                        </option>
                      ))}
                    </Select>
                  </GridItem>
                  <GridItem>
                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="400"
                      w={{ sm: '100%', md: 'auto' }}
                      padding={'6px 16px'}
                      h="40px"
                      _focus={{
                        borderColor: '#EEEFFC',
                      }}
                      tabIndex={0}
                      onClick={HandleAdd}
                    >
                      Add
                    </Button>
                  </GridItem>
                </>
              )}
              {form?.type == typeEnum.FILE && (
                <>
                  <Box
                    w={{ base: '100%', '2xl': '300px' }}
                    me="36px"
                    h={{ base: '220px', '2xl': '220px' }}
                    {...getRootProps()}
                    border="2px dashed"
                    borderColor="gray.300"
                    borderRadius="md"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                  >
                    <input {...getInputProps()} />
                    <Box
                      mt={50}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Icon
                        as={MdUpload}
                        w="80px"
                        h="80px"
                        color={brandColor}
                      />
                      <Flex justify="center" mx="auto" mb="12px">
                        <Text fontSize="xl" fontWeight="700" color={brandColor}>
                          Upload Files
                        </Text>
                      </Flex>
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color="secondaryGray.500"
                      >
                        PNG, JPG files && Video are allowed
                      </Text>
                    </Box>
                  </Box>
                  <GridItem>
                    {loader ? (
                      <Box
                        justifyContent={'center'}
                        display={'flex'}
                        mx="auto"
                        my={5}
                      >
                        <Spinner color="brand.500" />
                      </Box>
                    ) : (
                      form.fileDataForm.imagePath.length > 0 && (
                        <>
                          {fileType === 'image' && (
                            <Image
                              src={`${path()}${form.fileDataForm.imagePath}`}
                              me="36px"
                              h={{ base: '220px', '2xl': '220px' }}
                              color="inherit"
                            />
                          )}
                          {fileType === 'video' && (
                            <Box as="video" controls 
                            me="36px"
                            h={{ base: '220px', '2xl': '220px' }}>
                              <source
                                src={`${path()}${form.fileDataForm.imagePath}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </Box>
                          )}
                        </>
                      )
                    )}
                  </GridItem>
                  <GridItem>
                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="400"
                      w={{ sm: '100%', md: 'auto' }}
                      padding={'6px 16px'}
                      h="40px"
                      _focus={{
                        borderColor: '#EEEFFC',
                      }}
                      tabIndex={0}
                      onClick={HandleAdd}
                      disabled={loader}
                    >
                      Add
                    </Button>
                  </GridItem>
                </>
              )}
            </Grid>
          </FormControl>
          {form?.fileData.length > 0 && (
            <Box
              borderRadius="md"
              borderLeft="1px solid white"
              borderRight="1px solid white"
              boxShadow="md"
              marginTop={10}
              overflowX="auto" // Allow horizontal scrolling on smaller screens
            >
              <Table minWidth="100%">
                <Tr color="white">
                  <Th
                    textAlign="center"
                    bg="#B99E68"
                    color="white"
                    fontWeight="bold"
                  >
                    S.No.
                  </Th>
                  <Th
                    textAlign="center"
                    bg="#B99E68"
                    color="white"
                    fontWeight="bold"
                  >
                    Name
                  </Th>
                  <Th
                    textAlign="center"
                    bg="#B99E68"
                    color="white"
                    fontWeight="bold"
                  >
                    Is Private
                  </Th>
                  <Th
                    textAlign="center"
                    bg="#B99E68"
                    color="white"
                    fontWeight="bold"
                  >
                    Image/Folder
                  </Th>
                  <Th
                    textAlign="center"
                    bg="#B99E68"
                    color="white"
                    fontWeight="bold"
                  >
                    Delete
                  </Th>
                </Tr>
                {form?.fileData.map((data, index) => (
                  <Tr key={index} color="white">
                    <Td textAlign="center">{index + 1}</Td>
                    <Td textAlign="center">{data.fileName}</Td>
                    <Td textAlign="center">
                      {data.isPrivate ? 'Private' : 'Public'}
                    </Td>
                    <Td textAlign="center">
                      {data.type == typeEnum.FILE ? 'File' : 'Folder'}
                    </Td>
                    <Td justifyContent="center" alignItems="center">
                      <CloseButton onClick={() => handleDelete(index)} />
                    </Td>
                  </Tr>
                ))}
              </Table>
            </Box>
          )}
        </Box>
      )}
      <FooterButtons
        title={'Update Changes'}
        loading={buttonLoading}
        handleCancelButton={props?.handleClose}
        handleClick={handleAdd}
      />
    </>
  );
}

export default DraftAdd;

const planData = [
  {
    id: typeEnum.ALL,
    item: `Select One`,
  },
  {
    id: typeEnum.FILE,
    item: `FIle`,
  },
  {
    id: typeEnum.FOLDER,
    item: `Folder`,
  },
];

const IsPrivate = [
  {
    id: 1,
    item: `Private`,
  },
  {
    id: 0,
    item: `Public`,
  },
];
