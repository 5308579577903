import {
  SubscribeState,
  PlanTypeEnum,
  SubscribeStatusTypeEnum,
  OrderStatusEnum,
  SubscriptionTypeEnum,
} from '.';

export const initialState: SubscribeState = {
  loading: false,
  editLoading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  list: [],
  form: {
    _id: '',
    name: '',
    email: '',
    planType: PlanTypeEnum.FREE,
    planAmount: '',
    planExpiryDate: '',
    currency: '',
    isActive: SubscribeStatusTypeEnum.ACTIVE,
    createdAt: '',
    updatedAt: '',
    subscriptionCanceled: false,
    status: OrderStatusEnum.PENDING,
    subscriptionType: SubscriptionTypeEnum.MONTHLY,
    planName: '',
  },
  sortColumn: '',
  sortDir: '',
  add: false,
  buttonLoading: false,
  disableAccount: false,
  deleteAccount: false,
  listBilling: [],
  pageNoBilling: 1,
  pageSizeBilling: 5,
  totalRowBilling: 0,
  loadingBilling: false,
  planFilter: PlanTypeEnum.BASIC,
  planFilterArray: []
};
