import React, { useEffect, useState } from 'react';
import { Button, Flex, Progress, Stack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@chakra-ui/react';
import FileCard from './FileCard';
import Folder from './Folder';
import { DataInterface, FileTypeEnum } from './Redux/types';
import { selectList, selectProjectDetails } from './Redux/selector';
import { actions } from './Redux/slice';
import DragAndDropBox from './DragAndDropBox';
import CreateFolder from './CreateFolder';
import { useNavigate, useParams } from 'react-router-dom';
function Index() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const list = useSelector(selectList);
  const [createFolder, setCreateFolder] = useState(false);
  const projectDetails = useSelector(selectProjectDetails);
  useEffect(() => {
    console.log(params.fid, window.location, 'params');
    if (params.fid == null || params.fid == undefined) {
      navigate(`/admin/demo_project/${projectDetails?.fileId}`);
    }

    return () => {};
  }, [params, projectDetails]);

  useEffect(() => {
    dispatch(actions.doGetDemoProjectDetails());
    return () => {};
  }, []);
  useEffect(() => {
    if (params.fid) {
      dispatch(
        actions.doGetFolderFileList({
          id: params.fid,
          callback() {},
        }),
      );
    }

    return () => {};
  }, [params.fid]);
  const handleClose = () => {
    setCreateFolder(false);
  };
  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card backgroundColor={'#21232E'} boxShadow={'none'} borderRadius={'6px'}>
        <Stack direction={'row'} justifyContent={'space-around'}>
          <Button
            width={120}
            marginBottom={20}
            onClick={() => setCreateFolder(true)}
          >
            Create Folder
          </Button>
          <Button width={120} marginBottom={20} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Stack>
        <DragAndDropBox>
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            {list?.map((data: DataInterface) => (
              <>
                {data?.fileType == FileTypeEnum.FILE ? (
                  <FileCard data={data} key={data._id} />
                ) : (
                  <Folder data={data} key={data._id} />
                )}
              </>
            ))}
          </Grid>
        </DragAndDropBox>
        <CreateFolder
          open={createFolder}
          handleClose={handleClose}
          loading={false}
        />
      </Card>
    </Flex>
  );
}

export default Index;
