import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { RootState } from '../../typesNew';

const selectDomain = (state: RootState) => {
  if (state && state.DashboardState) {
    return state.DashboardState;
  } else {
    return initialState;
  }
};

export const selectEndDate = createSelector([selectDomain], (state) => state.endDate);
export const selectFilterValue = createSelector([selectDomain], (state) => state.filterValue);
export const selectStartDate = createSelector([selectDomain], (state) => state.startDate);
export const SelectChartData = createSelector([selectDomain], (state) => state.chartData);
export const SelectDashboardFormData = createSelector([selectDomain], (state) => state.DashboardFormData);