// Chakra imports
import { Flex, Text, Icon, useColorModeValue, Select } from '@chakra-ui/react';
import { useState } from 'react';
// Assets
import { MdOutlineLocationOn } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';
import CircularSlider from 'react-circular-slider-svg';
import CurrencyConvertor from 'components/CurrencyConvertor';

interface props {
  icon: any;
  headLine: string;
  amount: string;
  isDown: boolean;
  percentValue: string;
  isAmount?: boolean;
}

export default function CircularProgress(props: props) {
  const [temperature, setTemperature] = useState(21);
  // Chakra Color Mode
  const arcColor = useColorModeValue('#4318FF', '#7551FF');
  const arcBackgroundColor = useColorModeValue('#21232E', '#21232E');
  const textColor = useColorModeValue('white', 'white');
  const cardBg = useColorModeValue('white', 'navy.700');
  const iconStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '10px',
    backgroundColor: '#808B96',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    display: 'flex',
  };
  const iconStyleBetween = {
    width: '50px',
    height: '50px',
    borderRadius: '10px',
    backgroundColor: '#808B96',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    display: 'flex',
  };
  const iconStyleSmall = {
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#808B96',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    display: 'flex',
  };
  const iconStyleToUse =
    window.innerWidth <= 768
      ? iconStyleSmall
      : window.innerWidth <= 900
      ? iconStyle
      : iconStyleBetween;

  function formatPercentageWithColor(value: any) {
    // Remove the '%' sign and parse the value as a float
    const numericValue = parseFloat(value.replace('%', ''));

    // Determine the sign of the numeric value
    const sign = Math.sign(numericValue);

    // Define colors for positive and negative values
    const positiveColor = 'green';
    const negativeColor = 'red';

    // Choose color based on the sign
    const color = sign === 1 ? positiveColor : negativeColor;

    // Return the formatted value with appropriate color
    return <span style={{ color: color }}>{value}</span>;
  }
  return (
    <Card backgroundColor={arcBackgroundColor}>
      <Flex justifyContent="space-evenly" alignItems="center">
        <div style={{ flex: '0 0 20%' }}>
          <div style={iconStyleToUse}>{props.icon}</div>
        </div>
        <div style={{ flex: '1' }}>
          <Text
            fontSize={['sm', 'md', 'lg']} // Font size decreases as screen size decreases
            lineHeight="10"
            color={'#D2D3E0'}
            fontWeight="400"
          >
            {props.headLine}
          </Text>
          <Flex justifyContent="space-between">
            <Text
              fontSize={['md', 'lg', 'xl', '3xl']} // Font size decreases as screen size decreases
              lineHeight="10"
              color={textColor}
              fontWeight="bold"
            >
              {props.isAmount ? (
                <CurrencyConvertor value={Number(props.amount)} />
              ) : (
                props.amount
              )}
            </Text>
            <Text
              fontSize={['sm', 'md', 'lg', 'xl']} // Font size decreases as screen size decreases
              lineHeight="10"
              color={'#02B15A'}
              fontWeight="thin"
            >
              {formatPercentageWithColor(props.percentValue)}
            </Text>
          </Flex>
        </div>
      </Flex>
    </Card>
  );
}
