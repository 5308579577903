export interface DashboardState {
  DashboardFormData: DashboardInterface;
  filterValue: DateFilterTypeEnum;
  startDate: Date | string;
  endDate: Date | string;
  chartData: ResponseData;
}
export interface DashboardInterface {
  userCount: number;
  previousUserCount: number;
  subscription: number;
  previousSubscription: number;
  totalIncome: number;
  previousTotalIncome: number;
}
export interface ResponseData {
  date: string[]; // Array of month names
  subscriptions: number[]; // Array of numbers representing plan purchases per month
  userData: number[]; // Array of numbers representing user data per month
}
export enum DateFilterTypeEnum {
  NOTHING = -1,
  ALL = 0,
  TODAY = 1,
  WEEK = 7,
  MONTH = 30,
  YEAR = 365,
}
export type InitialDashboardState = DashboardState;
