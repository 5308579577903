import {
  Card,
  CardBody,
  Stack,
  Heading,
  Image,
  GridItem,
  IconButton,
} from '@chakra-ui/react';
import { path } from 'utils/Api';
import { DataInterface } from '../Redux/types';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { actions } from '../Redux/slice';
import { useParams } from 'react-router-dom';
type Props = {
  data: DataInterface;
};
function Index({ data }: Props) {
  const dispatch = useDispatch();
  const params = useParams();
  const handleDelete = (id: string) => {
    dispatch(
      actions.doDeleteFileAndFolder({
        id: id,
        callback() {
          dispatch(
            actions.doGetFolderFileList({
              id: params.fid,
              callback() {},
            }),
          );
        },
      }),
    );
  };
  return (
    <GridItem>
      <Card maxW="sm">
        <CardBody>
          <Image
            src={
              data?.fileThumbnailPath
                ? `${path()}${data?.fileThumbnailPath}`
                : `${path()}${data?.filePath}`
            }
            alt={data.fileThumbnailPath}
            borderRadius="lg"
          />
          <Stack mt="6" spacing="3" direction={'row'}>
            <Heading size="sm">{data.name}</Heading>
            <Heading size="sm">
              <IconButton
                onClick={() => handleDelete(data._id)}
                aria-label="Call Segun"
                size="lg"
                icon={<DeleteIcon />}
              />
            </Heading>
          </Stack>
        </CardBody>
      </Card>
    </GridItem>
  );
}

export default Index;
