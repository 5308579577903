import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { actions as actionUser} from '../User/slice';
import { AxiosResponse } from 'axios';
import {
  doGetSubscribeList,
  doGetSubscribeBillingList,
  CancelSubscription,
} from '../../utils/request';
import {
  selectForm,
  selectPageNo,
  selectPageNoBilling,
  selectPageSize,
  selectPageSizeBilling,
  selectPlanFilterArray,
  selectSearch,
  selectSortColumn,
  selectSortDir,
} from './selector';

import {
  selectSortColumn as sortColumn,
  selectSortDir as sortDir,
} from '../Plan/selector';
import CatchBlockFunction from 'components/hooks/CatchError';
import { useToast } from '@chakra-ui/react';
import { SubscribeInterface } from './types';
import { toast } from 'sonner';
import { passwordRegex } from 'utils/constants/Password';
import { emailRegex } from 'utils/constants/Email';

export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const filterArray: [] = yield select(selectPlanFilterArray);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetSubscribeList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`, 
      {planType: filterArray}
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0,
      ),
    );
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetBillingListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    if (action.payload.id) {
      const pageNo: number = yield select(selectPageNoBilling);
      const pageSize: number = yield select(selectPageSizeBilling);
      const SortColumn: string = yield select(sortColumn);
      const SortDir: string = yield select(sortDir);
      yield put(actions.setLoadingBilling(true));
      const response: AxiosResponse = yield call(
        doGetSubscribeBillingList,
        `?pageNo=${pageNo}&pageSize=${pageSize}${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
          SortDir.length > 0 ? '&sortDir=' + SortDir : ''
        }`,
        action.payload.id,
      );

      yield put(actions.setLoadingBilling(false));
      if (response && !response.data) {
        toast.error(response.data.message);
        return;
      }

      yield put(
        actions.setTotalRowBilling(
          response.data.data.meta ? response.data.data.meta.total : 0,
        ),
      );
      yield put(actions.setListBilling(response.data.data.results));
    }
  } catch (error: any) {
    yield put(actions.setLoadingBilling(false));
    CatchBlockFunction(error);
  }
}
export function* doCancelSubscriptionRequest(action: {
  payload: {id :string , callback: any };
}) {
  yield delay(500);
  yield put(actionUser.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(CancelSubscription , action.payload.id);
    if (response && !response.data) {
      toast.error(response.data.data.message);
      return;
    }
    yield put(actionUser.setButtonLoading(false));
    yield call(action.payload.callback(response.data.data.status));
  } catch (error: any) {
    yield put(actionUser.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* SubscribeStateRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetBillingList, doGetBillingListRequest);
  yield takeLatest(actions.doCancelSubscription, doCancelSubscriptionRequest);
}
