import React from 'react';
import { Box, Button, theme } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { AddIcon } from '@chakra-ui/icons';

interface props {
  title: string;
  loading: boolean;
  handleCancelButton: any;
  handleClick: any;
}

function FooterButtons(props: props) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '32px',
          columnGap: { sm: '16px', md: 0 },
        }}
      >
        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="400"
          w={{ sm: '100%', md: 'auto' }}
          padding={'6px 16px'}
          h="40px"
          _focus={{
            borderColor: '#EEEFFC',
          }}
          tabIndex={0}
          onClick={props?.handleClick}
          isLoading={props?.loading}
          isDisabled={props?.loading}
        >
          {props?.title}
        </Button>
      </Box>
    </>
  );
}

export default FooterButtons;
