/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchTableUsers from 'views/admin/main/users/overview/components/SubscribeList';
import {
  selectList,
  selectPageNo,
  selectPageSize,
  selectSearch
} from '../../Redux/Subscribe/selector';
import { actions } from '../../Redux/Subscribe/slice';

function List() {
  const data = useSelector(selectList);
  const pageNo = useSelector(selectPageNo);
  const pageSize = useSelector(selectPageSize);
  const search = useSelector(selectSearch);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.doGetList());
  }, [pageNo, pageSize, search]);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card backgroundColor={'#21232E'} boxShadow={'none'} borderRadius={'6px'}>
        <SearchTableUsers data={data} />
      </Card>
    </Flex>
  );
}

export default List;
