"use client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  IUserDataInterface,
} from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsToken: (state, action: PayloadAction<string | null>) => {
      state.isToken = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearFrom: (state) => {
      state.form.email = "";
      state.form.password = "";
    },
    doLogin: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => { },
    doLogOut: (state) => {
      localStorage.removeItem("token");
    },
    updatePasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `password.${action.payload.key}`, action.payload.value);
    },
    clearPasswordFrom: (state) => {
      state.password.password = "";
      state.password.confirmPassword = "";
    },

    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },

    doGetProfile: (
      state,
      action: PayloadAction<{
        token: string;
        callback: (data: { role: number; id: string }) => void;
      }>
    ) => { },

    doGetUserProfile: (state) => { },
    setProfileData: (state, action: PayloadAction<IUserDataInterface>) => {
      state.userData = action.payload;
    },
    toggleShowPassword: (state) => {
      state.togglePassword = !state.togglePassword;
    },
    toggleConfirmPassword: (state) => {
      state.toggleConfirmPassword = !state.toggleConfirmPassword;
    },
    doUpdatePassword: (
      state,
      action: PayloadAction<{ callback: (token: string) => void }>
    ) => { },


  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
