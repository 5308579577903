import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface props {
  title: string;
  open: any;
  handleClose: any;
  children?: any;
  width?: any;
}

function ModalTemplate(props: props) {
  return (
    <>
      <Box>
        <Modal isCentered isOpen={props?.open} onClose={props?.handleClose}>
          <ModalOverlay />
          <ModalContent
            backgroundColor={'#21232E'}
            padding={'40px'}
            width={
              props?.width ? props?.width : { sm: '95%', md: '70%', lg: 560 }
            }
            maxWidth={'auto'}
            borderRadius={'12px'}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: 6,
                borderBottom: '1px solid #313248',
                marginBottom: 8,
              }}
            >
              <Text
                sx={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: '#EEEFFC',
                  lineHeight: '24.2px',
                }}
              >
                {props?.title}
              </Text>
              <CloseIcon
                w={4}
                h={4}
                color={'#D2D3E0'}
                cursor={'pointer'}
                onClick={props?.handleClose}
              />
            </Box>
            <Box>{props?.children}</Box>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}

export default ModalTemplate;
