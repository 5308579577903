import React from 'react';
//@ts-ignore
import CurrencyFormat from 'react-currency-format';
interface Props {
  value: number;
}

function Index(props: Props) {
  return (
    <CurrencyFormat
      value={props?.value?.toFixed(0)}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'¥ '}
    />
  );
}

export default Index;
