// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import Configurator from 'components/navbar/Configurator';
import routes from 'routes';
import { useNavigate } from 'react-router-dom';
import { IUserDataInterface } from 'Redux/Login/types';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../Redux/Login/selector';

export default function HeaderLinks(props: {
  secondary: boolean;
  [x: string]: any;
}) {
  const { secondary, theme, setTheme } = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.400', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px #313248',
    '14px 17px 40px 4px #313248)',
  );
  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem('token');
    navigate('/auth/login');
  };
  const handleGo = () => {
    navigate('/auth/forgot-password/centered');
  };
  const userData = useSelector(selectUserData);

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      // bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      justifyContent={"space-between"}
      borderRadius="999px"
      // boxShadow={shadow}
    >
       <SidebarResponsive routes={routes} />
    
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color={textColor}
            // name="Adela Parkson"
            bg={textColorBrand}
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          backgroundColor={'#21232E'}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid #313248"
              fontSize="sm"
              fontWeight="700"
              color={'#D2D3E0'}
            >
              👋&nbsp; Hey, {userData.name}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              backgroundColor="#21232E"
               
              _hover={{ backgroundColor: '#41455b'}} // Change colors on hover
              color="#D2D3E0"
              borderRadius="8px"
              px="14px"
              onClick={handleGo}
            >
              <Text fontSize="sm">
                Update Password
              </Text>
            </MenuItem>

            <MenuItem
              backgroundColor="#21232E"
              _focus={{ backgroundColor: '#21232E' }}
              _hover={{ backgroundColor: '#41455b', color: 'red.400' }} // Change colors on hover
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={handleLogOut}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
