/* eslint-disable */

import {
  Flex,
  useColorModeValue,
  Box,
  Spinner,
  Grid,
  GridItem,
  FormLabel,
  Container,
} from '@chakra-ui/react';
import * as React from 'react';
// Assets
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

import { ProjectData } from '../../Redux/Plan/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAdd,
  selectDraftData,
  selectLoading,
} from '../../Redux/Plan/selector';
import { actions } from '../../Redux/Plan/slice';
import DraftAdd from 'components/UserList/DraftAdd';

export default function PlanList(props: { data: ProjectData }) {
  const { data } = props;
  const add = useSelector(selectAdd);
  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(actions.toggleAdd(false));
    dispatch(actions.clearFrom());
  };
  const dataNew = useSelector(selectDraftData)
  React.useEffect(() => {
    dispatch(actions.toggleDraftForm());
  }, [dataNew])
  return (
    <Box marginRight={"15%"} marginLeft={"15%"} backgroundColor={"transparent"} >
      <DraftAdd id={null} open={add} handleClose={handleClose} />
    </Box>
  );
}
