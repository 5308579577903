// Chakra imports
import { Flex, Text, useColorModeValue, Image } from '@chakra-ui/react';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';
import ContrastBlockDark from 'assets/img/newimages/namelogo.png';
export function SidebarBrand(props: { mini: boolean; hovered: boolean }) {
  const { mini, hovered } = props;
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');

  return (
    <Flex alignItems="center" flexDirection="column">
       <Image
        src={ContrastBlockDark}
        maxW={{ base: '60%', md: '220px' }}
        mb={3}
        // boxShadow={shadowBlock}
      />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
