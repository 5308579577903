export interface UserInterface {
  _id: string;
  name: string;
  email: string;
  password: string;
  totalProject: number;
  plan: PlanTypeEnum;
  isActive: StatusTypeEnum;
  actions: boolean;
  profilePic: string;
}

export enum StatusTypeEnum {
  ACTIVE = 1,
  INACTIVE = 2,
}
export enum PlanTypeEnum {
  FREE = 1,
  BASIC = 2,
  PROFESSIONAL = 3,
}

export interface BalcncelistInterface {
  _id: string;
  orderId: string;
  currency: string;
  amount_received: number;
  subscriptionType: number;
  planId: string;
  userId: string;
  discount: number;
  payment_id: string;
  code: string;
  transactionDate: string;
  expiryDate: string;
  invoiceNumber: string;
  balanceAmount: number;
  createdAt: string;
  name: string;
  email: string;
}
export interface UserState {
  form: UserInterface;
  loading: boolean;
  editLoading: boolean;
  list: UserInterface[];
  Balcncelist: BalcncelistInterface[];
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  add: boolean;
  buttonLoading: boolean;
  disableAccount: boolean;
  deleteAccount: boolean;
}

export type InitialUserState = UserState;
