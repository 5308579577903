import React from 'react';
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDraftData,selectList
} from '../../Redux/Plan/selector';
import { actions } from '../../Redux/Plan/slice';
import DraftData from './DraftData';

function List() {
  const data = useSelector(selectDraftData);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.doGetDemoProjectList());
    dispatch(actions.doGetList());
  }, []);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card backgroundColor={'#21232E'} boxShadow={'none'} borderRadius={'6px'}>
        <DraftData data={data} />
      </Card>
    </Flex>
  );
}

export default List;
