import { DashboardState } from '.';

export const initialState: DashboardState = {
  filterValue: 0,
  startDate: new Date(),
  endDate: new Date(),
  DashboardFormData: {
      userCount: 0,
      previousUserCount: 0,
      subscription: 0,
      previousSubscription: 0,
      totalIncome: 0,
      previousTotalIncome: 0
  },
  chartData: {
    date: [],
    subscriptions: [],
    userData: [],
  },
};
