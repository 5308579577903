import {
  Card,
  CardBody,
  Stack,
  Heading,
  Image,
  GridItem,
  IconButton,
} from '@chakra-ui/react';
import { DataInterface } from '../Redux/types';
import { ArrowRightIcon, DeleteIcon, LockIcon } from '@chakra-ui/icons';
import { actions } from '../Redux/slice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
type Props = {
  data: DataInterface;
};
function Index({ data }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const handleDelete = (id: string) => {
    dispatch(
      actions.doDeleteFileAndFolder({
        id: id,
        callback() {
          dispatch(
            actions.doGetFolderFileList({
              id: params.fid,
              callback() {},
            }),
          );
        },
      }),
    );
  };
  const handleNavigate = (id: string) => {
    navigate(`/admin/demo_project/${id}`);
  };

  return (
    <GridItem>
      <Card maxW="sm" bg={data?.color ? data?.color : 'auto'}>
        <CardBody>
          <IconButton
            onClick={() => handleNavigate(data._id)}
            aria-label="Call Segun"
            size="xl"
            icon={<ArrowRightIcon />}
          />{' '}
          <Heading size="md" color={'#fff'} mt={2}>
            {data.name}
          </Heading>
          <Stack mt="6" spacing="3" direction={'row'}>
            <Heading size="md">
              <IconButton
                onClick={() => handleDelete(data._id)}
                aria-label="Call Segun"
                size="lg"
                icon={<DeleteIcon />}
              />
            </Heading>
            {data.isPrivate == true && (
              <Heading size="md">
                <IconButton
                  aria-label="Call Segun"
                  size="lg"
                  icon={<LockIcon />}
                />
              </Heading>
            )}
          </Stack>
        </CardBody>
      </Card>
    </GridItem>
  );
}

export default Index;
