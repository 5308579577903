import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { selectButtonLoading } from '../../Redux/User/selector';
import FooterButtons from 'components/FooterButtons/Index';
import ModalTemplate from 'components/ModalTemplate/Index';
import React from 'react';
import { useSelector } from 'react-redux';
import { PlanTypeEnum, StatusTypeEnum } from '../../Redux/User/types';

interface props {
  id: string;
  open: boolean;
  title?: string;
  buttonTitle: string;
  content: string;
  handleClose: any;
  handleClick: any;
}

function ConfirmModal(props: props) {
  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const textColorSecondary = 'gray.400';
  const loading = useSelector(selectButtonLoading);

  return (
    <>
      <ModalTemplate
        title={props?.title}
        open={props?.open}
        handleClose={props?.handleClose}
      >
        <Box>
          <Text color={textColor}>{props?.content}</Text>
        </Box>
        <FooterButtons
          title={props?.buttonTitle}
          loading={loading}
          handleCancelButton={props?.handleClose}
          handleClick={props?.handleClick}
        />
      </ModalTemplate>
    </>
  );
}

export default ConfirmModal;