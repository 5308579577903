
import React from 'react';
import { Box } from '@chakra-ui/react';

import SignOut from 'assets/toater-success.svg';
import alert from 'assets/alert.png';
import { useNavigate } from 'react-router-dom';
function PaymentConfirmation() {
  const [loading, setLoading] = React.useState(false);
  const navigate  = useNavigate();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      navigate(-1);
    }, 3000); 

    return () => clearTimeout(timer);
  }, [navigate]);
  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '100%',
          minHeight: 'calc(100vh - 84px)',
          display:"flex" , justifyContent:"center",
          alignContent:"center",
          alignItems:"center"
        }}
      >
        {loading ? (
          <Box>
            
          </Box>
        ) : (
          <>
            <Box style={{
              display:"flex" , justifyContent:"center",
              alignContent:"center",
              alignItems:"center"
            }}>
              <Box style={{
                marginRight:20
              }} >
                <img src={SignOut} alt="Sign Out"  style={{
                  height:70,
                }}/>
              </Box>
              <Box>
                <p
                  style={{
                    fontSize: 38,
                    fontWeight: 600,
                    textAlign: 'center',
                    color: '#D2D3E0',
                  }}
                >
                 Your subscription cancellation has been successfully processed
                </p>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default PaymentConfirmation;
