/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AmountPending from 'views/admin/main/users/overview/components/amountPending';
import {
  selectBalcncelist,
  selectPageNo,
  selectPageSize,
  selectSearch
} from '../../Redux/User/selector';
import { actions } from '../../Redux/User/slice';

function List() {
  const data = useSelector(selectBalcncelist);
  const pageNo = useSelector(selectPageNo);
  const pageSize = useSelector(selectPageSize);
  const search = useSelector(selectSearch);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.doGetAmountHistoryList());
  }, [pageNo, pageSize, search]);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card backgroundColor={'#21232E'} boxShadow={'none'} borderRadius={'6px'}>
        <AmountPending data={data} />
      </Card>
    </Flex>
  );
}

export default List;
