/* eslint-disable */

import {
  Badge,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Box,
  FormControl,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  ResponsiveValue,
  useBreakpointValue,
  FormLabel,
} from '@chakra-ui/react';
import { Select as ChakraSelect } from '@chakra-ui/react';
import { default as ReactSelect } from 'react-select';

import { toast } from 'sonner';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import * as React from 'react';
// Assets
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

import {
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import {
  SubscribeInterface,
  OrderStatusEnum,
  SubscriptionTypeEnum,
  PlanTypeEnum,
} from '../../../../../../Redux/Subscribe/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPageNo,
  selectPageSize,
  selectLoading,
  selectTotalRow,
  selectSearch,
  selectDisableAccountModal,
  selectDeleteAccountModal,
  selectAdd,
  selectListBilling,
  selectSortColumn,
  selectSortDir,
  selectPlanFilter,
  selectPlanFilterArray,
} from '../../../../../../Redux/Subscribe/selector';
import { actions } from '../../../../../../Redux/Subscribe/slice';
import ConfirmModal from 'components/UserList/ConfirmModal';
import TeamUpdate from 'components/TeamUpdate';
import styled from '@emotion/styled';
import BIllingHistory from './BIllingHistory';
import dateFormat from 'components/hooks/dateFormat';
import CurrencyConvertor from 'components/CurrencyConvertor';
import { selectList } from '../../../../../../Redux/Plan/selector';
import { actions as PlanAction } from '../../../../../../Redux/Plan/slice';

export default function SearchTableOrders(props: { data: any }) {
  const { data } = props;
  React.useEffect(() => {
    table.getHeaderGroups();
    getPaginationRowModel();
    return () => {};
  }, [data]);
  const SortColumn = useSelector(selectSortColumn);
  const SortDir = useSelector(selectSortDir);
  const pageNo = useSelector(selectPageNo);
  const pagesize = useSelector(selectPageSize);
  const loading = useSelector(selectLoading);
  const totalCount = useSelector(selectTotalRow);
  const disableModal = useSelector(selectDisableAccountModal);
  const deleteModal = useSelector(selectDeleteAccountModal);
  const [selectedId, setSelectedId] = React.useState('');
  const [selectedName, setSelectedName] = React.useState('');
  const [activeStatus, setActiveStatus] = React.useState<number>(0);
  const dispatch = useDispatch();
  const PlanData = useSelector(selectList);

  React.useEffect(() => {
    table.getHeaderGroups();
    getPaginationRowModel();
    return () => {};
  }, []);

  const handleCloseDisableModal = () => {
    dispatch(actions.toggleAdd(false));
    setSelectedId('');
    setSelectedName('');
  };

  const handleDisable = () => {
    dispatch(
      actions.doDisableAccount({
        id: selectedId,
        status: activeStatus == 1 ? 2 : 1,
        callback: () => {
          dispatch(actions.toggleAdd(false));
          dispatch(actions.doGetList());
        },
      }),
    );
  };
  const handleCloseDeleteModal = () => {
    dispatch(actions.toggleDeleteAccountModal(false));
    setSelectedId('');
    setSelectedName('');
  };
  const navigate = useNavigate();
  const handleDelete = () => {
    dispatch(
      actions.doCancelSubscription({
        id: selectedId,
        callback(data) {
          dispatch(actions.toggleDeleteAccountModal(false));
          if (data) {
            navigate('/CancelSubscriptionConfirmation');
          }
          dispatch(actions.doGetList());
          console.log(data, 'data');
        },
      }),
    );
  };

  const handleTeamUpdate = (id: string) => {
    setSelectedId(id);
    dispatch(
      actions.doUpdate({
        callback: () => {
          dispatch(actions.doGetList());
        },
      }),
    );
  };

  React.useEffect(() => {
    
    dispatch(PlanAction.doGetList());

    return () => {
      
    }
  }, [])
  
  const openModel = useSelector(selectAdd);
  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const borderColor = useColorModeValue('#2C2D3C', '#2C2D3C');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = React.useState('');
  const columnHelper = createColumnHelper<SubscribeInterface>();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      enableSorting: true,
      header: 'Name',
      // sortDescFirst: false,
      sortingFn: 'text',
    }),
    columnHelper.accessor('email', {
      id: 'email',
      enableSorting: true,
      header: 'Email Address',
      // sortDescFirst: false,
      sortingFn: 'text',
    }),
    columnHelper.accessor('planName', {
      id: 'planName',
      header: 'Active Plan',
      cell: (info: any) => <Text>{info.getValue()}</Text>,
    }),
    columnHelper.accessor('subscriptionType', {
      id: 'subscriptionType',
      header: 'Subscription Type',
      cell: (info: any) => (
        <Text>
          {info.getValue() == SubscriptionTypeEnum.MONTHLY
            ? 'Monthly'
            : info.getValue() == SubscriptionTypeEnum.YEARLY
            ? 'Yearly'
            : ''}
        </Text>
      ),
    }),
    columnHelper.accessor('planAmount', {
      id: 'planAmount',
      header: 'Amount',
      cell: (info: any) => <CurrencyConvertor value={info.getValue()} />,
    }),
    columnHelper.accessor('planExpiryDate', {
      id: 'planExpiryDate',
      header: 'Next Billing Cycle',
      cell: (info: any) => <Text>{dateFormat(info.getValue())}</Text>,
    }),

    columnHelper.accessor('status', {
      id: 'status',
      header: 'Subscription Status',
      cell: (info: any) => (
        <Badge
          variant="outline"
          textTransform="capitalize"
          color={
            info.getValue() === OrderStatusEnum.SUCCESS ? '#F3A79F' : '#FF3B2E'
          }
          boxShadow={`inset 0 0 0 1px ${
            info.getValue() === OrderStatusEnum.SUCCESS ? '#F3A79F' : '#FF3B2E'
          }`}
        >
          {info.getValue() == OrderStatusEnum.CANCELLED
            ? 'Cancelled'
            : info.getValue() == OrderStatusEnum.FAILED
            ? 'Failed'
            : info.getValue() == OrderStatusEnum.PENDING
            ? 'Pending'
            : 'Success'}
        </Badge>
      ),
    }),
    columnHelper.accessor('_id', {
      id: '_id',
      header: 'Action',
      cell: (info: any) => {
        console.log(info.row.original.subscriptionCanceled, 'info');

        return (
          <Menu size={'10px'}>
            <MenuButton
              onClick={() => {
                setActiveStatus(info.cell.row.original.isActive);
              }}
            >
              <Text
                marginLeft={2}
                fontSize={21}
                color={'white'}
                cursor={'pointer'}
              >
                ...
              </Text>
            </MenuButton>
            <MenuList
              bgColor={'#21232E'}
              borderColor={'#313248'}
              _hover={{
                backgroundColor: '#21232E',
              }}
            >
              <MenuItem
                backgroundColor="#21232E"
                _hover={{ backgroundColor: '#41455b' }} // Change colors on hover
                color="#D2D3E0"
                borderRadius="8px"
                px="14px"
                onClick={() => {
                  dispatch(actions.toggleAdd(true));
                  dispatch(actions.setListBilling([]));
                  dispatch(actions.setTotalRowBilling(0));
                  dispatch(actions.setPageNoBilling(1));
                  dispatch(
                    actions.doGetBillingList({
                      id: info.getValue(),
                      callback() {},
                    }),
                  );
                  dispatch(actions.clearFrom());
                  setSelectedId(info.getValue());
                  setSelectedName(info.row.original.name);
                }}
              >
                View Billing History
              </MenuItem>
              <MenuItem
                backgroundColor="#21232E"
                _hover={{ backgroundColor: '#41455b' }} // Change colors on hover
                color="#D2D3E0"
                borderRadius="8px"
                px="14px"
                onClick={() => {
                  if (info.row.original.subscriptionCanceled) {
                    toast.error('Subscription is already cancelled');
                    return;
                  }
                  dispatch(actions.toggleDeleteAccountModal(true));
                  setSelectedId(info.getValue());
                  setSelectedName(info.row.original.name);
                }}
              >
                Cancel Subscription
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    }),
  ];
  const [sorting, setSorting] = React.useState([]);
  const table = useReactTable({
    data: data,
    columns: columns,
    state: {
      columnFilters,
      globalFilter,
      sorting,
    },
    pageCount: Math.ceil(totalCount / pagesize),
    sortDescFirst: true,
    enableMultiSort: true,
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });
  React.useEffect(() => {
    if (sorting.length > 0) {
      dispatch(actions.setSortColumn(sorting[0].id));
      dispatch(actions.setSortDir(sorting[0].desc ? 'desc' : 'asc'));
    }

    return () => {};
  }, [sorting]);
  const createPages = (
    count: number,
    currentPage: number,
    maxButtons: number,
  ) => {
    let arrPageCount: any = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, count);
    startPage = Math.max(endPage - maxButtons + 1, 1);

    for (let i = startPage; i <= endPage; i++) {
      arrPageCount.push(i);
    }

    if (startPage > 1) {
      arrPageCount.unshift('...');
    }
    if (endPage < count) {
      arrPageCount.push('...');
    }

    return arrPageCount;
  };

  // In your component
  const pageCount = table.getPageCount();
  const pages = createPages(pageCount, pageNo, 5);
  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name') {
        table.setSorting([{ id: 'name', desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);

  const billingData = useSelector(selectListBilling);
  const flexDirection: ResponsiveValue<'row' | 'column'> = useBreakpointValue({
    base: 'column',
    md: 'row',
  });

  const [selectedPlans, setSelectedPlans] = React.useState([]);
  console.log(selectedPlans, 'Selected Options');
  const handleSelectChange = (selectedOptions: any) => {
    setSelectedPlans(
      selectedOptions ? selectedOptions.map((option: any) => option.value) : [],
    );
    dispatch(
      actions.setPlanFilterArray(
        selectedOptions
          ? selectedOptions.map((option: any) => option.value)
          : [],
      ),
    );
  };
  console.log(selectedPlans, 'selectedPlans');

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#1B1C20',
      borderColor: state.isFocused ? '#313248' : '#313248', // Maintain the same border color on focus
      boxShadow: state.isFocused ? 'none' : 'none', // Remove the focus box shadow
      '&:hover': {
        borderColor: '#313248', // Maintain the same border color on hover
      },
      width: '400px',
      height: '50px'
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#21232E',
      color: '#D2D3E0',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#313248',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#D2D3E0',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#313248' : '#21232E',
      color: '#D2D3E0',
      '&:hover': {
        backgroundColor: '#313248',
      },
    }),
  };

  console.log(PlanData , "PlanData");
  
  const options = PlanData.map((data) => ({
    value: data.planType,
    label: data.name,
  }));
  const optionsPage = [5, 10, 20, 40, 50, 100, 200];
  const filter = useSelector(selectPlanFilterArray)

  React.useEffect(() => {
    dispatch(actions.doGetList());
    return () => {};
  }, [SortDir, SortColumn , filter]);
  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex justify={'space-between'} w="100%" mb="28px">
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
            placeholder="Search..."
          />
          <Box>
            <ReactSelect
              isMulti
              name="plans"
              options={options}
              styles={customStyles}
              value={options.filter((option) =>
                selectedPlans.includes(option.value),
              )}
              onChange={handleSelectChange}
            />
          </Box>
        </Flex>

        {loading ? (
          <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
            <Spinner color="brand.500" />
          </Box>
        ) : (
          <>
            {table.getRowModel().rows.length > 0 ? (
              <Box overflowX="auto" mb="24px">
                <Table variant="simple" color="gray.500" minWidth="600px">
                  <Thead backgroundColor={'#2C2D3C'}>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header: any) => {
                          return (
                            <Th
                              pe="10px"
                              borderColor={borderColor}
                              key={header.id}
                              colSpan={header.colSpan}
                              style={{
                                color: '#D2D3E0',
                                fontWeight: 400,
                                cursor: 'pointer',
                                textTransform: 'capitalize',
                              }}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                            </Th>
                          );
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map((row: any) => {
                      return (
                        <Tr px="20px" key={row.id}>
                          {row.getVisibleCells().map((cell: any) => {
                            return (
                              <Td
                                key={cell.id}
                                color={textColor}
                                fontSize={{ sm: '12px', md: '14px' }}
                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                borderColor={borderColor}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            ) : (
              <Text
                fontSize="sm"
                color={textColor}
                fontWeight="normal"
                textAlign={'center'}
                marginRight={20}
              >
                No Records Available
              </Text>
            )}
            {data.length != 0 && !loading && (
              <Flex
                w="100%"
                direction={flexDirection}
                justify="space-between"
                align={'center'}
                px="20px"
                pt="10px"
                pb="5px"
              >
                <Box>
                  <Stack
                    direction="row"
                    alignSelf="flex-end"
                    alignItems={'center'}
                    spacing="4px"
                    ms="auto"
                  >
                    <Text
                      fontSize="sm"
                      color={textColor}
                      fontWeight="normal"
                      marginRight={2}
                    >
                      Show
                    </Text>
                    <FormControl
                      style={{
                        marginLeft: 0,
                        backgroundColor: 'transparent',
                      }}
                    >
                      <ChakraSelect
                        variant="outline"
                        color={textColor}
                        borderWidth={0.5}
                        width={20}
                        height={8}
                        value={pagesize}
                        borderRadius={4}
                        borderColor={'#2C2D3C'}
                        _hover={{
                          borderColor: '#2C2D3C',
                        }}
                        _focus={{
                          borderColor: '#2C2D3C',
                        }}
                        focusBorderColor="gray.500"
                        onChange={(e:any) => {
                          dispatch(actions.setPageNo(1));
                          dispatch(actions.setPageSize(Number(e.target.value)));
                        }}
                        sx={{
                          '> option': {
                            background: '#21232E',
                            color: '#D2D3E0',
                          },
                          '> option:hover': {
                            background: 'red',
                            color: 'pink',
                          },
                        }}
                      >
                        {optionsPage.map((data) => (
                          <option key={data} value={data}>
                            {data}
                          </option>
                        ))}
                      </ChakraSelect>
                    </FormControl>
                    <Text
                      fontSize="sm"
                      color={textColor}
                      fontWeight="normal"
                      marginLeft={2}
                    >
                      entries
                    </Text>
                  </Stack>
                </Box>
                <div className="flex items-center gap-2">
                  <Stack
                    direction="row"
                    alignSelf="flex-end"
                    spacing="4px"
                    ms="auto"
                  >
                    <Button
                      variant="no-effects"
                      onClick={() => {
                        if (pageNo === 1 || data.length === 0) {
                          return;
                        }
                        dispatch(actions.setPageNo(pageNo - 1));
                      }}
                      disabled={pageNo === 1 || data.length === 0}
                      transition="all .5s ease"
                      color={
                        pageNo === 1 || data.length === 0 ? 'gray' : '#D2D3E0'
                      }
                      fontWeight={400}
                      fontSize={14}
                      h="28px"
                      borderRadius="8px"
                      bg="transparent"
                    >
                      <Icon
                        as={MdChevronLeft}
                        w="26px"
                        h="26px"
                        color={'#B99E68'}
                      />
                      Previous
                    </Button>
                    {Array.isArray(pages) &&
                      pages.map((pageNumber, index) => {
                        return (
                          <Button
                            variant="no-effects"
                            transition="all .5s ease"
                            onClick={() => {
                              if (pageNumber !== '...') {
                                dispatch(actions.setPageNo(Number(pageNumber)));
                              }
                            }}
                            w="28px"
                            h="28px"
                            minW={'auto'}
                            borderRadius="8px"
                            padding={'6px'}
                            fontWeight={400}
                            bg={
                              pageNumber === pageNo ? brandColor : 'transparent'
                            }
                            key={index}
                          >
                            <Text
                              fontSize="sm"
                              color={pageNumber === pageNo ? '#fff' : textColor}
                            >
                              {pageNumber}
                            </Text>
                          </Button>
                        );
                      })}
                    <Button
                      variant="no-effects"
                      onClick={() => {
                        if (pageNo >= Math.ceil(totalCount / pagesize)) {
                          return;
                        }
                        dispatch(actions.setPageNo(pageNo + 1));
                      }}
                      disabled={pageNo >= Math.ceil(totalCount / pagesize)}
                      transition="all .5s ease"
                      color={
                        pageNo >= Math.ceil(totalCount / pagesize)
                          ? 'gray'
                          : '#D2D3E0'
                      }
                      fontWeight={400}
                      fontSize={14}
                      h="28px"
                      borderRadius="8px"
                      bg="transparent"
                    >
                      Next
                      <Icon
                        as={MdChevronRight}
                        w="26px"
                        h="26px"
                        color={'#B99E68'}
                      />
                    </Button>
                  </Stack>
                </div>

                <Text
                  fontSize="sm"
                  color={textColor}
                  fontWeight="normal"
                  mb={{ sm: '24px', md: '0px' }}
                >
                  Page {pageNo} to{' '}
                  {pageNo * pagesize > totalCount
                    ? totalCount
                    : pageNo * pagesize}{' '}
                  of {totalCount}
                </Text>
              </Flex>
            )}
          </>
        )}
      </Flex>
      <TeamUpdate handleSubmit={handleTeamUpdate} />
      <BIllingHistory
        id={selectedId}
        open={openModel}
        handleClose={handleCloseDisableModal}
        handleClick={handleDisable}
        data={billingData}
      />
      <ConfirmModal
        id={selectedId}
        title={'Are you sure?'}
        open={deleteModal}
        buttonTitle={'Yes, Cancel Subscription'}
        content={`Would you like to cancel ${selectedName} active subscription? The user will be automatically shifted to the free plan once the billing period ends. ?`}
        handleClose={handleCloseDeleteModal}
        handleClick={handleDelete}
      />
    </>
  );
}
// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  const dispatch = useDispatch();
  const search = useSelector(selectSearch);
  React.useEffect(() => {
    if (search.length > 0) {
      dispatch(actions.setPageNo(1));
    }
    return () => {};
  }, [search]);
  const handleChange = (e: any) => {
    dispatch(actions.setSearch(e.target.value));
  };
  return (
    <>
      <SearchBar
        {...props}
        value={search}
        handleChange={handleChange}
        h="36px"
        w={{ sm: '190px', lg: '280px' }}
        borderRadius="4px"
        background="#21232E"
      />
    </>
  );
}
export const StyledOption = styled.option`
  :hover {
    background-color: yellow;
  }

  background-color: red;
  :active {
    background_color: pink;
  }
`;

const planData = [
  {
    id: PlanTypeEnum.FREE,
    item: `Free`,
  },
  {
    id: PlanTypeEnum.BASIC,
    item: `Basic`,
  },
  {
    id: PlanTypeEnum.PROFESSIONAL,
    item: `Paid`,
  },
];
