import { StatusTypeEnum } from '../../../Redux/User/types';
import { PlanTypeEnum, PlanState, typeEnum } from '.';

export const initialState: PlanState = {
  loading: false,
  editLoading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  list: [],
  form: {
    japaneseName: '',
    japaneseFeatureList: [],
    _id: '',
    name: '',
    projects: 0,
    users: 0,
    storage: 0,
    monthlyAmount: 0,
    yearlyAmount: 0,
    versioning: false,
    deleteProject: false,
    planType: PlanTypeEnum.FREE,
    createdAt: '',
    isActive: StatusTypeEnum.ACTIVE,
    updatedAt: '',
    reviewers: 0,
    featureList: [],
    sortOrder: 0,
    mostPopular: false,
    fileMaxRatio: 0,
    description: '',
    japaneseDescription: '',
  },
  sortColumn: '',
  sortDir: '',
  add: false,
  buttonLoading: false,
  disableAccount: false,
  featureForm: {
    _id: '',
    name: '',
    japaneseName : ""
  },
  featureList: [],
  draftForm: {
    _id: '',
    title: '',
    description: '',
    fileData: [],
    fileDataForm: {
      fileName: '',
      type: typeEnum.ALL,
      isPrivate: false,
      imageSize: 0,
      folderColor: '#EEEFFC',
      imagePath: '',
      _id: '',
      color: '#EEEFFC',
      isNew: false,
    },
    type: typeEnum.ALL,
  },
  draftData: {
    _id: '',
    title: '',
    description: '',
    fileData: [],
  },
};
