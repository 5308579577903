import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import {
  selectPageNoBilling,
  selectPageSizeBilling,
  selectTotalRowBilling,
  selectloadingBilling,
} from '../../../../../../Redux/Subscribe/selector';
import React, { useEffect, useRef } from 'react';
import { MdChevronRight, MdChevronLeft, MdMoreHoriz } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../../../Redux/Subscribe/slice';
import { actions as plan_action } from '../../../../../../Redux/Plan/slice';
import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
} from '@chakra-ui/react';

import {
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  PaymentStatusEnum,
  listBillingInterface,
} from '../../../../../../Redux/Subscribe/types';
import dateFormat from 'components/hooks/dateFormat';
import { GoDownload } from 'react-icons/go';
import { apiPath } from 'utils/Api';
import {
  selectSortColumn,
  selectSortDir,
} from '../../../../../../Redux/Plan/selector';
import CurrencyConvertor from 'components/CurrencyConvertor';
interface props {
  id: string;
  open: boolean;
  title?: string;
  handleClose: any;
  handleClick: any;
  data: any;
}
function ConfirmModal(props: props) {
  const btnRef = React.useRef(null);
  const { data } = props;
  React.useEffect(() => {
    table.getHeaderGroups();
    getPaginationRowModel();
    return () => {};
  }, [data]);

  const pageNo = useSelector(selectPageNoBilling);
  const loader = useSelector(selectloadingBilling);
  const pagesize = useSelector(selectPageSizeBilling);
  const totalCount = useSelector(selectTotalRowBilling);
  const SortColumn = useSelector(selectSortColumn);
  const SortDir = useSelector(selectSortDir);
  React.useEffect(() => {
    dispatch(
      actions.doGetBillingList({
        id: props.id,
        callback() {},
      }),
    );
    return () => {};
  }, [SortDir, SortColumn]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.doGetBillingList({
        id: props.id,
        callback() {},
      }),
    );
    return () => {};
  }, [pageNo]);
  React.useEffect(() => {
    table.getHeaderGroups();
    getPaginationRowModel();
    return () => {};
  }, []);
  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const borderColor = useColorModeValue('#2C2D3C', '#2C2D3C');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const handleDownload = () => {
    // Add logic to handle the download action
  };

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const handleViewInvoice = (invoiceNumber: string) => {
    let token = localStorage.getItem('token');
    window.open(
      `${apiPath()}order/generate-invoice?invoiceNumber=${invoiceNumber}&token=${token}`,
    );
  };
  const columnHelper = createColumnHelper<listBillingInterface>();
  const columns = [
    columnHelper.accessor('transactionDate', {
      id: 'transactionDate',
      header: 'Date',
      cell: (info: any) => <Text>{dateFormat(info.getValue())}</Text>,
    }),
    columnHelper.accessor('invoiceNumber', {
      id: 'invoiceNumber',
      header: 'Invoice Number',
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      cell: (info: any) => (
        <Text>
          {info.getValue() == PaymentStatusEnum.FAILED
            ? 'Failed'
            : info.getValue() == PaymentStatusEnum.CANCELLED
            ? 'Cancelled'
            : 'Paid'}
        </Text>
      ),
    }),
    columnHelper.accessor('amount', {
      id: 'amount',
      header: 'Amount',
      cell: (info: any) => <CurrencyConvertor value={info.getValue()} />,
    }),
    columnHelper.accessor('invoiceNumber', {
      id: 'invoiceNumber',
      header: 'Download',
      cell: (info: any) => (
        <Button
          onClick={() => handleViewInvoice(info.getValue())}
          colorScheme="transparent"
          leftIcon={<Icon as={GoDownload} />}
        ></Button>
      ),
    }),
  ];
  const [sorting, setSorting] = React.useState([]);
  const table = useReactTable({
    data: data,
    columns: columns,
    state: {
      columnFilters,
      sorting,
    },
    pageCount: Math.ceil(totalCount / pagesize),
    sortDescFirst: true,
    enableMultiSort: true,
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });
  const createPages = (
    count: number,
    currentPage: number,
    maxButtons: number,
  ) => {
    let arrPageCount: any = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, count);
    startPage = Math.max(endPage - maxButtons + 1, 1);

    for (let i = startPage; i <= endPage; i++) {
      arrPageCount.push(i);
    }

    if (startPage > 1) {
      arrPageCount.unshift('...');
    }
    if (endPage < count) {
      arrPageCount.push('...');
    }

    return arrPageCount;
  };

  const pageCount = table.getPageCount();
  const pages = createPages(pageCount, pageNo, 5);
  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name') {
        table.setSorting([{ id: 'name', desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);
  const modalRef = useRef(null);

  const handleClick = (event: any) => {
    if (modalRef.current === event.target) {
      props.handleClose();
    }
  };

  React.useEffect(() => {
    if (sorting.length > 0) {
      dispatch(plan_action.setSortColumn(sorting[0].id));
      dispatch(plan_action.setSortDir(sorting[0].desc ? 'desc' : 'asc'));
    }

    return () => {};
  }, [sorting]);
  return (
    <>
      <Modal
        isCentered
        onClose={props.handleClose}
        finalFocusRef={btnRef}
        scrollBehavior={'inside'}
        isOpen={props?.open}
      >
        <ModalOverlay />

        <ModalContent
          backgroundColor="#21232E"
          width="80%" // Set the desired width here, such as "80%"
          maxWidth="2xl" // Set the maximum width, such as "xl" for extra-large
          margin="auto" // Center the modal horizontally
        >
          <ModalHeader color={'white'}>Billing History</ModalHeader>
          <ModalCloseButton color={'white'} _focus={{ boxShadow: 'none' }} />
          <Box border="1px solid #313248" borderRadius="md"></Box>
          <ModalBody marginTop={5}>
            <>
              {loader ? (
                <Box
                  justifyContent={'center'}
                  display={'flex'}
                  mx="auto"
                  my={5}
                >
                  <Spinner color="brand.500" />
                </Box>
              ) : table.getRowModel().rows.length > 0 ? (
                <Table variant="simple" color="gray.500" mb="24px">
                  <Thead backgroundColor={'#2C2D3C'}>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header: any) => {
                          return (
                            <Th
                              pe="10px"
                              borderColor={borderColor}
                              key={header.id}
                              colSpan={header.colSpan}
                              style={{
                                color: '#D2D3E0',
                                fontWeight: 400,
                                cursor: 'pointer',
                                textTransform: 'capitalize',
                              }}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                            </Th>
                          );
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map((row: any) => {
                      return (
                        <Tr px="20px" key={row.id}>
                          {row.getVisibleCells().map((cell: any) => {
                            return (
                              <Td
                                key={cell.id}
                                color={textColor}
                                fontSize={{ sm: '12px', md: '14px' }}
                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                borderColor={borderColor}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <Text
                  fontSize="sm"
                  color={textColor}
                  fontWeight="normal"
                  textAlign={'center'}
                  marginRight={20}
                >
                  No Records Available
                </Text>
              )}
            </>
          </ModalBody>
          <ModalFooter>
            {data.length != 0 && !loader && (
              <Flex
                w="100%"
                justify="center"
                align={'center'}
                px="20px"
                pt="10px"
                pb="5px"
              >
                <div className="flex items-center gap-2">
                  <Stack
                    direction="row"
                    alignSelf="flex-end"
                    spacing="4px"
                    ms="auto"
                  >
                    <Button
                      variant="no-effects"
                      onClick={() => {
                        if (pageNo === 1 || data.length === 0) {
                          return;
                        }
                        dispatch(actions.setPageNoBilling(pageNo - 1));
                      }}
                      disabled={pageNo === 1 || data.length === 0}
                      transition="all .5s ease"
                      color={
                        pageNo === 1 || data.length === 0 ? 'gray' : '#D2D3E0'
                      }
                      fontWeight={400}
                      fontSize={14}
                      h="28px"
                      borderRadius="8px"
                      bg="transparent"
                    >
                      <Icon
                        as={MdChevronLeft}
                        w="26px"
                        h="26px"
                        color={'#B99E68'}
                      />
                      Previous
                    </Button>
                    {Array.isArray(pages) &&
                      pages.map((pageNumber, index) => {
                        return (
                          <Button
                            variant="no-effects"
                            transition="all .5s ease"
                            onClick={() => {
                              if (pageNumber !== '...') {
                                dispatch(
                                  actions.setPageNoBilling(Number(pageNumber)),
                                );
                              }
                            }}
                            w="28px"
                            h="28px"
                            minW={'auto'}
                            borderRadius="8px"
                            padding={'6px'}
                            fontWeight={400}
                            bg={
                              pageNumber === pageNo ? brandColor : 'transparent'
                            }
                            key={index}
                          >
                            <Text
                              fontSize="sm"
                              color={pageNumber === pageNo ? '#fff' : textColor}
                            >
                              {pageNumber}
                            </Text>
                          </Button>
                        );
                      })}
                    <Button
                      variant="no-effects"
                      onClick={() => {
                        if (pageNo >= Math.ceil(totalCount / pagesize)) {
                          return;
                        }
                        dispatch(actions.setPageNoBilling(pageNo + 1));
                      }}
                      disabled={pageNo >= Math.ceil(totalCount / pagesize)}
                      transition="all .5s ease"
                      color={
                        pageNo >= Math.ceil(totalCount / pagesize)
                          ? 'gray'
                          : '#D2D3E0'
                      }
                      fontWeight={400}
                      fontSize={14}
                      h="28px"
                      borderRadius="8px"
                      bg="transparent"
                    >
                      Next
                      <Icon
                        as={MdChevronRight}
                        w="26px"
                        h="26px"
                        color={'#B99E68'}
                      />
                    </Button>
                  </Stack>
                </div>
              </Flex>
            )}

            {/* <Button onClick={props.handleClose}>Close</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmModal;
