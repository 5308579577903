import { Image } from '@chakra-ui/react';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';
//icons
// import DraftList from 'views/Draft/list';
import History from 'assets/img/newimages/History.svg';
import Home from 'assets/img/newimages/Home.svg';
import Payment from 'assets/img/newimages/Payment.svg';
import User from 'assets/img/newimages/People.svg';
import SignOut from 'assets/img/newimages/SignOut.svg';

import Plan from 'assets/img/newimages/Plan.svg';

//Custom
import AmountHistory from 'views/amountHistory/index';
import Demo_Project from 'views/Demo_Project';
import PlanList from 'views/Plan/index';
import Subscribe from 'views/Subscribe/list';
import UserList from 'views/user/list';
const handleLogout = () => {
  localStorage.clear();
};
const routes: any = [
  // --- Dashboards ---
  {
    name: 'Dashboards',
    layout: '/admin',
    visibleSidebar: true,
    path: '/dashboards',
    icon: <Image src={Home} width="25px" height="25px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Manage Users',
    layout: '/admin',
    path: '/user-list',
    visibleSidebar: true,
    icon: <Image src={User} width="25px" height="25px" color="inherit" />,
    component: <UserList />,
  },
  {
    name: 'Amount History',
    layout: '/admin',
    visibleSidebar: true,
    path: '/amount-list',
    icon: <Image src={History} width="25px" height="25px" color="inherit" />,
    component: <AmountHistory />,
  },
  {
    name: 'Manage Plan',
    layout: '/admin',
    visibleSidebar: true,
    path: '/plan-list',
    icon: <Image src={Plan} width="20px" height="23px" color="inherit" />,
    component: <PlanList />,
  },
  {
    name: 'Manage Subscriptions',
    layout: '/admin',
    visibleSidebar: true,
    path: '/subscriptions-list',
    icon: <Image src={Payment} width="25px" height="25px" color="inherit" />,
    component: <Subscribe />,
  },
  {
    name: 'Demo Project',
    layout: '/admin',
    visibleSidebar: false,
    path: '/demo_project/:fid',
    icon: <Image src={Payment} width="25px" height="25px" color="inherit" />,
    component: <Demo_Project />,
  },
  {
    name: 'Log Out',
    layout: '/auth',
    visibleSidebar: true,
    path: '/login',
    icon: (
      <Image
        src={SignOut}
        width="25px"
        height="25px"
        color="inherit"
        onClick={handleLogout}
      />
    ),
    // onClick: handleLogout(),
  },
];

export default routes;
