/* eslint-disable */

import {
  Badge,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from '@chakra-ui/react';
import * as React from 'react';
// Assets
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

import {
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import { DataInterface, PlanTypeEnum } from '../../Redux/Plan/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPageNo,
  selectPageSize,
  selectAdd,
  selectLoading,
  selectTotalRow,
  selectSearch,
  selectDisableAccountModal,
} from '../../Redux/Plan/selector';
import { actions } from '../../Redux/Plan/slice';
import UpdatePlan from './form';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import ConfirmModal from 'components/UserList/ConfirmModal';
import { StatusTypeEnum } from '../../Redux/User/types';
import dateFormat from 'components/hooks/dateFormat';

export default function PlanList(props: { data: any }) {
  const { data } = props;
  React.useEffect(() => {
    table.getHeaderGroups();
    getPaginationRowModel();
    return () => {};
  }, [data]);

  const pageNo = useSelector(selectPageNo);
  const pagesize = useSelector(selectPageSize);
  const loading = useSelector(selectLoading);
  const totalCount = useSelector(selectTotalRow);
  const disableModal = useSelector(selectDisableAccountModal);
  const [selectedId, setSelectedId] = React.useState('');
  const [activeStatus, setActiveStatus] = React.useState<number>(0);
  const dispatch = useDispatch();

  const add = useSelector(selectAdd);
  React.useEffect(() => {
    handleClose();
    table.getHeaderGroups();
    getPaginationRowModel();
    return () => {};
  }, []);

  const handleClose = () => {
    dispatch(actions.toggleAdd(false));
    dispatch(actions.clearFrom());
    setSelectedId('');
  };
  const handleCloseDisableModal = () => {
    dispatch(actions.toggleDisableAccountModal(false));
    setSelectedId('');
  };
  const handleDisable = () => {
    dispatch(
      actions.doDisableAccount({
        id: selectedId,
        status: activeStatus == 1 ? 2 : 1,
        callback: () => {
          dispatch(actions.toggleDisableAccountModal(false));
          dispatch(actions.doGetList());
        },
      }),
    );
  };

  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const borderColor = useColorModeValue('#2C2D3C', '#2C2D3C');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = React.useState('');
  const columnHelper = createColumnHelper<DataInterface>();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name',

      cell: (info) => {
        return (
          <Flex w="100%" justify="start" align={'center'}>
            <Text marginLeft={2}>
              {info.getValue()?.length > 40
                ? info.getValue()?.slice(0, 40)
                : info.getValue()}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('projects', {
      id: 'projects',
      enableSorting: true,
      header: 'Projects',
      sortDescFirst: false,
      sortingFn: 'text',
    }),
    columnHelper.accessor('users', {
      id: 'users',
      header: 'Total Users',
    }),
    columnHelper.accessor('monthlyAmount', {
      id: 'monthlyAmount',
      enableSorting: true,
      header: 'Monthly Amount',
      sortDescFirst: false,
      sortingFn: 'text',
    }),
    columnHelper.accessor('yearlyAmount', {
      id: 'yearlyAmount',
      header: 'Yearly Amount',
    }),
    columnHelper.accessor('storage', {
      id: 'storage',
      header: 'Storage',
    }),
    columnHelper.accessor('fileMaxRatio', {
      id: 'fileMaxRatio',
      header: 'File Max Ratio',
    }),
    columnHelper.accessor('mostPopular', {
      id: 'mostPopular',
      header: 'Most Popular',
      cell: (info: any) => (
        <Text>{info.getValue() == true ? 'Yes' : 'No'}</Text>
      ),
    }),
    columnHelper.accessor('planType', {
      id: 'planType',
      header: 'Plan',
      cell: (info: any) => (
        <Text>
          {info.getValue() == PlanTypeEnum.FREE
            ? 'Free'
            : info.getValue() == PlanTypeEnum.BASIC
            ? 'Basic'
            : info.getValue() == PlanTypeEnum.PROFESSIONAL
            ? 'Professional'
            : ''}
        </Text>
      ),
    }),
    columnHelper.accessor('sortOrder', {
      id: 'sortOrder',
      header: 'SortOrder',
      cell: (info: any) => <Text>{info.getValue()}</Text>,
    }),
    columnHelper.accessor('versioning', {
      id: 'versioning',
      header: 'Versions',
      cell: (info: any) => (
        <Text>{info.getValue() == true ? 'Yes' : 'No'}</Text>
      ),
    }),
    columnHelper.accessor('deleteProject', {
      id: 'deleteProject',
      header: 'Delete Project',
      cell: (info: any) => (
        <Text>{info.getValue() == true ? 'Yes' : 'No'}</Text>
      ),
    }),
    columnHelper.accessor('updatedAt', {
      id: 'updatedAt',
      header: 'Updated At',
      cell: (info: any) => <Text>{dateFormat(info.getValue())}</Text>,
    }),
    columnHelper.accessor('_id', {
      id: '_id',
      header: 'Action',
      cell: (info: any) => (
        <EditIcon
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(actions.toggleAdd(true));
            dispatch(actions.clearFrom());
            setSelectedId(info.getValue());
          }}
        />
      ),
    }),
  ];
  const [sorting, setSorting] = React.useState([]);
  const table = useReactTable({
    data: data,
    columns: columns,
    state: {
      columnFilters,
      globalFilter,
      sorting,
    },
    pageCount: Math.ceil(totalCount / pagesize),
    sortDescFirst: true,
    enableMultiSort: true,
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });
  React.useEffect(() => {
    console.log(sorting, 'sorting');

    return () => {};
  }, [sorting]);
  const createPages = (
    count: number,
    currentPage: number,
    maxButtons: number,
  ) => {
    let arrPageCount = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, count);
    startPage = Math.max(endPage - maxButtons + 1, 1);

    for (let i = startPage; i <= endPage + 1; i++) {
      arrPageCount.push(i);
    }

    if (startPage > 1) {
      arrPageCount.unshift('...');
    }
    if (endPage < count) {
      arrPageCount.push('...');
    }

    return arrPageCount;
  };

  // In your component
  const pageCount = table.getPageCount();
  const pages = createPages(pageCount, pageNo, 5);
  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name') {
        table.setSorting([{ id: 'name', desc: false }]);
      }
    }

    console.log(pageCount, pageNo, 'pageCount');
  }, [table.getState().columnFilters[0]?.id]);

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex align={'center'} justify={'space-between'} w="100%" mb="28px">
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow border border-block"
            placeholder="Search..."
          />
        </Flex>
        {loading ? (
          <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
            <Spinner color="brand.500" />
          </Box>
        ) : (
          <>
            <Box overflowX="auto" mb="24px">
              {table.getRowModel().rows.length > 0 ? (
                <Table variant="simple" color="gray.500" minWidth="600px">
                  <Thead backgroundColor={'#2C2D3C'}>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header: any) => {
                          return (
                            <Th
                              pe="10px"
                              borderColor={borderColor}
                              key={header.id}
                              colSpan={header.colSpan}
                              style={{
                                color: '#D2D3E0',
                                fontWeight: 400,
                                cursor: 'pointer',
                                textTransform: 'capitalize',
                              }}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                            </Th>
                          );
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map((row: any) => {
                      return (
                        <Tr px="20px" key={row.id}>
                          {row.getVisibleCells().map((cell: any) => {
                            return (
                              <Td
                                key={cell.id}
                                color={textColor}
                                fontSize={{ sm: '12px', md: '14px' }}
                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                borderColor={borderColor}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <Text
                  fontSize="sm"
                  color={textColor}
                  fontWeight="normal"
                  textAlign={'center'}
                  marginRight={20}
                >
                  No Records Available
                </Text>
              )}
            </Box>
          </>
        )}
      </Flex>
      <UpdatePlan id={selectedId} open={add} handleClose={handleClose} />
      <ConfirmModal
        id={selectedId}
        title={`${activeStatus == 1 ? 'Disable' : 'Enable'} Account`}
        open={disableModal}
        buttonTitle={activeStatus == 1 ? 'Disable' : 'Enable'}
        content={`Do you want to ${
          activeStatus == 1 ? 'Disable' : 'Enable'
        } this account ?`}
        handleClose={handleCloseDisableModal}
        handleClick={handleDisable}
      />
    </>
  );
}
// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  const dispatch = useDispatch();
  const search = useSelector(selectSearch);
  const handleAdd = () => {
    dispatch(actions.toggleAdd(true));
  };
  React.useEffect(() => {
    if (search.length > 0) {
      dispatch(actions.setPageNo(1));
    }
    return () => {};
  }, [search]);
  const handleChange = (e: any) => {
    dispatch(actions.setSearch(e.target.value));
  };
  return (
    <>
      <SearchBar
        {...props}
        value={search}
        handleChange={handleChange}
        h="36px"
        w={{ sm: '190px', lg: '280px' }}
        borderRadius="4px"
        background="#21232E"
      />
    </>
  );
}
