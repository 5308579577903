import { StatusTypeEnum } from '../../../Redux/User/types';

export interface DataInterface {
  _id: string;
  name: string;
  description: string;
  japaneseDescription: string;
  japaneseName: string;
  projects: number;
  users: number;
  storage: number;
  monthlyAmount: number;
  mostPopular: boolean;
  fileMaxRatio: number;
  yearlyAmount: number;
  versioning: boolean;
  deleteProject: boolean;
  planType: PlanTypeEnum;
  createdAt: string;
  isActive: StatusTypeEnum;
  updatedAt: string;
  reviewers: number;
  sortOrder: number;
  featureList: [];
  japaneseFeatureList?: [];
}
export interface featureFormInterface {
  _id: string;
  name: string;
  japaneseName: string;
}
export interface fileDataInterface {
  fileName: string;
  type: typeEnum;
  isPrivate: boolean;
  imageSize: number;
  folderColor: string;
  imagePath: string;
  _id: string;
  color: string;
  isNew: boolean;
}

export enum typeEnum {
  ALL = 3,
  FOLDER = 1,
  FILE = 0,
}
export interface draftFormInterface {
  _id: string;
  title: string;
  description: string;
  fileData: fileDataInterface[];
  fileDataForm: fileDataInterface;
  type: typeEnum;
}
export enum PlanTypeEnum {
  FREE = 1,
  BASIC = 2,
  PROFESSIONAL = 3,
}
export interface FileData {
  _id: string;
  fileName: string;
  color: string;
  type: typeEnum;
  isPrivate: boolean;
  imageSize?: number;
  imagePath?: string;
}

export interface ProjectData {
  _id: string;
  title: string;
  description: string;
  fileData: FileData[];
}
export interface ProjectDataForm {
  _id: string;
  title: string;
  description: string;
  fileData: FileData[];
  fileDataForm: fileDataInterface;
  type: typeEnum;
}
export interface PlanState {
  list: DataInterface[];
  form: DataInterface;
  loading: boolean;
  editLoading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  add: boolean;
  buttonLoading: boolean;
  disableAccount: boolean;
  featureForm: featureFormInterface;
  featureList: featureFormInterface[];
  draftForm: ProjectDataForm;
  draftData: ProjectData;
}

export type InitialPlanState = PlanState;
