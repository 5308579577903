import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { selectTogglePassword } from '../../Redux/Login/selector';
import {
  selectButtonLoading,
  selectEditLoading,
  selectForm,
} from '../../Redux/User/selector';
import { toast } from 'sonner';
import { actions } from '../../Redux/User/slice';
import { actions as loginActions } from '../../Redux/Login/slice';
import FooterButtons from 'components/FooterButtons/Index';
import ModalTemplate from 'components/ModalTemplate/Index';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { PlanTypeEnum, StatusTypeEnum } from '../../Redux/User/types';
import PasswordValidation from 'components/PasswordValidation';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface props {
  id: string;
  open: boolean;
  handleClose: any;
}

function Add(props: props) {
  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const textColorSecondary = 'gray.400';
  const form = useSelector(selectForm);
  const show = useSelector(selectTogglePassword);
  const buttonLoading = useSelector(selectButtonLoading);
  const loading = useSelector(selectEditLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.id) {
      dispatch(actions.doGetEdit(props.id));
    }
    return () => {};
  }, [props.id]);

  const handleShow = () => {
    dispatch(loginActions.toggleShowPassword());
  };
  const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };
  const handleFieldNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(
      actions.updateFormValue({
        key: name,
        value: value.replace(/[^\w\s]/gi, ''),
      }),
    );
  };
  const handleSelectChange = (name: string, evt: any) => {
    dispatch(actions.updateFormValue({ key: name, value: evt }));
  };
  const nameRegex = /^[A-Za-z]+([ '-][A-Za-z]+)*$/;
  const handleAdd = () => {
    if (form.name.trim().length == 0) {
      toast.error('Please enter name');
      return;
    }
    if (!nameRegex.test(form.name.trim())) {
      toast.error('Please enter valid name');
      return;
    }
    dispatch(actions.setSearch(''));
    if (props?.id) {
      dispatch(
        actions.doUpdate({
          callback: () => {
            props?.handleClose();
            dispatch(actions.doGetList());
          },
        }),
      );
    } else {
      dispatch(
        actions.doAdd({
          callback: () => {
            props?.handleClose();
            dispatch(actions.doGetList());
          },
        }),
      );
    }
  };
  useEffect(() => {
    if (props.id) {
      dispatch(actions.setEditLoading(true));
    }
    return () => {};
  }, [props.id]);

  return (
    <>
      <ModalTemplate
        title={props?.id ? 'Edit User' : 'Add New User'}
        open={props?.open}
        handleClose={props?.handleClose}
      >
        {loading ? (
          <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
            <Spinner color="brand.500" />
          </Box>
        ) : (
          <Box>
            <FormControl>
              <Grid rowGap={'20px'}>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Name<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="text"
                    placeholder="Enter name"
                    size="md"
                    name="name"
                    value={form.name}
                    maxLength={255}
                    onChange={handleFieldNameChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email Address<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    disabled={form._id ? true : false}
                    isRequired={true}
                    variant="auth"
                    autoComplete="off"
                    fontSize="15"
                    bgColor={form?._id ? '#313248' : '#1B1C20'}
                    type="email"
                    placeholder="Enter email address"
                    size="md"
                    name="email"
                    value={form.email}
                    onChange={handleFieldChange}
                  />
                </GridItem>
                {!props.id && (
                  <GridItem>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Password<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        isRequired={true}
                        placeholder="Enter password"
                        size="md"
                        type={show ? 'text' : 'password'}
                        variant="auth"
                        fontSize="15"
                        borderColor={'#313248'}
                        backgroundColor={'#1B1C20'}
                        borderRadius={'4px'}
                        autoComplete="off"
                        _placeholder={{
                          color: '#64656D',
                        }}
                        name="password"
                        value={form?.password}
                        onChange={handleFieldChange}
                      />
                      <InputRightElement display="flex" alignItems="center">
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: 'pointer' }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleShow}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <PasswordValidation password={form?.password} />
                  </GridItem>
                )}

                {/* <GridItem>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Subscription Plan<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Select
                    _hover={{
                      borderColor: '#313248',
                    }}
                    _focus={{
                      borderColor: '#313248',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    _active={{
                      borderColor: '#313248',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    variant="outline"
                    size="md"
                    fontSize="15"
                    disabled
                    borderColor={'#313248'}
                    backgroundColor={'#1B1C20'}
                    borderRadius={'4px'}
                    name="plan"
                    color={textColor}
                    value={form?.plan}
                    sx={{
                      '> option': {
                        background: '#21232E',
                        color: '#D2D3E0',
                      },
                    }}
                  >
                    {planData.map((data: any) => (
                      <option key={data?.id} value={data?.id}>
                        {data?.item}
                      </option>
                    ))}
                  </Select>
                </GridItem> */}
                <GridItem>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Status
                  </FormLabel>
                  <Menu placement="top">
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      _hover={{ borderColor: '#313248' }}
                      _focus={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      _active={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      variant="auth"
                      size="md"
                      textAlign={'start'}
                      fontSize="15"
                      value={form?.isActive}
                      width={'100%'}
                      borderColor="#313248"
                      backgroundColor="#1B1C20"
                      borderRadius="4px"
                      color={textColor}
                    >
                      {statusData.find((data) => data.id === form?.isActive)
                        ?.item || 'Select an option'}
                    </MenuButton>
                    <MenuList
                      backgroundColor="#1B1C20"
                      borderColor="#313248"
                      width={'100%'}
                      padding={0}
                    >
                      {statusData.map((data) => (
                        <MenuItem
                          key={data.id}
                          width={'100%'}
                          style={{
                            width: '100%',
                          }}
                          value={data.id}
                          onClick={() =>
                            handleSelectChange('isActive', data.id)
                          }
                          _hover={{
                            backgroundColor: '#313248',
                            color: '#D2D3E0',
                          }}
                          backgroundColor="#21232E"
                          color="#D2D3E0"
                        >
                          {data.item}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </GridItem>
              </Grid>
            </FormControl>
          </Box>
        )}
        <FooterButtons
          title={props.id ? 'Save Changes' : 'Add User'}
          loading={buttonLoading}
          handleCancelButton={props?.handleClose}
          handleClick={handleAdd}
        />
      </ModalTemplate>
    </>
  );
}

export default Add;
const statusData = [
  {
    id: StatusTypeEnum.ACTIVE,
    item: `Active`,
  },
  {
    id: StatusTypeEnum.INACTIVE,
    item: `In Active`,
  },
];
const planData = [
  {
    id: PlanTypeEnum.FREE,
    item: `Free`,
  },
  {
    id: PlanTypeEnum.BASIC,
    item: `Basic`,
  },
  {
    id: PlanTypeEnum.PROFESSIONAL,
    item: `Paid`,
  },
];
const optionsStyle = {
  backgroundColor: '#21232E',
  color: '#D2D3E0',
  '.options: hover': {
    backgroundColor: 'rgb(49, 50, 72) !important',
  },
  '.options:placeholder': {
    backgroundColor: '#21232E',
    color: '#D2D3E0',
  },
};
