import { WarningIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { passwordRegex } from 'utils/constants/Password';

const PasswordValidation = ({ password }: any) => {
  let count = /^.{8,}/.test(password) ? '' : 'minimum 8 characters,';
  let upperCase = /^(?=.*?[A-Z])/.test(password)
    ? ''
    : 'at least one uppercase letter,';
  let lowerCase = /^(?=.*?[a-z])/.test(password) ? '' : 'one lowercase letter,';
  let numberCount = /^(?=.*?[0-9])/.test(password) ? '' : 'one number,';
  let specialChar = /^(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(password)
    ? ''
    : 'one special character';
  let errorMessage = `Password must contain ${count} ${upperCase} ${lowerCase} ${numberCount} ${specialChar}`;
  return (
    <div>
      {password !== '' && !passwordRegex.test(password) && (
        <PasswordValidationTypo>
          <WarningIcon
            sx={{
              color: '#D2D3E0',
              mr: '6px',
              mt: '1.5px',
            }}
            w={3}
            h={3}
          />
          {errorMessage.replace(/,\s*$/, '')}
        </PasswordValidationTypo>
      )}
    </div>
  );
};
export default PasswordValidation;
const PasswordValidationTypo = styled(Box)({
  display: 'flex',
  fontSize: 10,
  color: '#D2D3E0',
  marginTop: 6,
});
