import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../typesNew";

const selectDomain = (state: RootState) => {
  if (state && state.authState) {
    return state.authState;
  } else {
    return initialState;
  }
};

export const selectUserData = createSelector(
  [selectDomain],
  (state) => state.userData
);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectIsLogin = createSelector(
  [selectDomain],
  (state) => state.isLogin
);
export const SelectToken = createSelector(
  [selectDomain],
  (state) => state.token
);
export const selectForm = createSelector(
  [selectDomain],
  (state) => state.form
);
export const selectPassword = createSelector(
  [selectDomain],
  (state) => state.password
);

export const selectIsToken = createSelector(
  [selectDomain],
  (state) => state.isToken
);
export const selectTogglePassword = createSelector(
  [selectDomain],
  (state) => state.togglePassword
);
export const selectToggleConfirmPassword = createSelector(
  [selectDomain],
  (state) => state.toggleConfirmPassword
);

