import moment from "moment";

const dateFormat = (data: string, time?: boolean) => {
  if (time == true) {
    let timeString = moment(data).startOf("m").fromNow(true);
    if (timeString == "a few seconds") {
      return "just now";
    } else if (
      timeString.split(" ")[0] == "a" ||
      timeString.split(" ")[0] == "an"
    ) {
      let strDate = timeString.split(" ");
      strDate[0] = "1";
      strDate[1] = strDate[1][0];
      return strDate.join("");
    } else {
      let strDate = timeString.split(" ");
      strDate[1] = strDate[1][0];
      return strDate.join("");
    }
  } else {
    const formatedData = moment(data).format('MMM DD, YYYY');
    return formatedData;
  }
};

export default dateFormat;
