import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Spinner,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import Circle from '@uiw/react-color-circle';
import FooterButtons from 'components/FooterButtons/Index';
import ModalTemplate from 'components/ModalTemplate/Index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreateFolder, selectProjectDetails } from '../Redux/selector';
import { actions } from '../Redux/slice';
import { useParams } from 'react-router-dom';

type Props = {
  id?: string;
  open: boolean;
  handleClose: any;
  loading: boolean;
};

function Index(props: Props) {
  const form = useSelector(selectCreateFolder);
  const params = useParams();
  const projectDetails = useSelector(selectProjectDetails);
  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const dispatch = useDispatch();
  const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFolderFormValue({ key: name, value: value }));
  };
  const handleColorChange = (color: string) => {
    dispatch(
      actions.updateFolderFormValue({
        key: 'color',
        value: color,
      }),
    );
  };
  const handleSelectPrivateChange = (name: string, evt: any) => {
    dispatch(
      actions.updateFolderFormValue({
        key: name,
        value: Number(evt.target.value),
      }),
    );
  };
  const handleFolderCreate = () => {
    dispatch(
      actions.updateFolderFormValue({
        key: 'projectId',
        value: projectDetails._id,
      }),
    );
    dispatch(
      actions.updateFolderFormValue({
        key: 'fileId',
        value: params.fid,
      }),
    );
    if (form?.color?.length === 0) {
      dispatch(
        actions.updateFolderFormValue({ key: 'color', value: '#21232E' }),
      );
    }
    dispatch(
      actions.doCreateFolder({
        callback: () => {
          dispatch(
            actions.doGetFolderFileList({
              id: params.fid,
              callback() {},
            }),
          );
          dispatch(actions.clearFrom());
          props?.handleClose();
        },
      }),
    );
  };
  return (
    <div>
      <ModalTemplate
        title={props?.id ? 'Update Folder' : 'Create Folder'}
        open={props?.open}
        handleClose={props?.handleClose}
      >
        {props.loading ? (
          <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
            <Spinner color="brand.500" />
          </Box>
        ) : (
          <Box>
            <FormControl>
              <Grid rowGap={'20px'}>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Folder Name<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="text"
                    placeholder="Enter name"
                    size="md"
                    name="name"
                    value={form?.name}
                    maxLength={255}
                    onChange={handleFieldChange}
                  />
                </GridItem>
                <GridItem display={'block'} justifyContent={'center'}>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Folder Color<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Box width={'70%'}>
                    <Circle
                      colors={colors}
                      color={form?.color}
                      className="circleContainer"
                      onChange={(color: any) => {
                        handleColorChange(color.hex);
                      }}
                    />
                  </Box>
                </GridItem>
                <GridItem>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Is Private <Text color={brandStars}>* </Text>
                  </FormLabel>
                  <Select
                    _hover={{
                      borderColor: '#313248',
                    }}
                    _focus={{
                      borderColor: '#313248',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    _active={{
                      borderColor: '#313248',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    variant="outline"
                    size="md"
                    fontSize="15"
                    borderColor={'#313248'}
                    backgroundColor={'#1B1C20'}
                    borderRadius={'4px'}
                    name="plan"
                    color={textColor}
                    value={Number(form?.isPrivate)}
                    sx={{
                      '> option': {
                        background: '#21232E',
                        color: '#D2D3E0',
                      },
                    }}
                    onChange={(e) => handleSelectPrivateChange('isPrivate', e)}
                  >
                    {IsPrivate.map((data: any) => (
                      <option key={data?.id} value={data?.id}>
                        {data?.item}
                      </option>
                    ))}
                  </Select>
                </GridItem>
              </Grid>
            </FormControl>
          </Box>
        )}
        <FooterButtons
          title={props.id ? 'Save Changes' : 'Add'}
          loading={props.loading}
          handleCancelButton={props?.handleClose}
          handleClick={handleFolderCreate}
        />
      </ModalTemplate>
    </div>
  );
}

export default Index;
const colors = [
  '#EEEFFC',
  '#AAC9F1',
  '#8C2D2D',
  '#307DDE',
  '#1199B1',
  '#AD90F9',
  '#FF3B2E',
  '#FFBFA8',
  '#FEE581',
  '#495D54',
  '#96D8BB',
  '#FF6537',
  '#FFA149',
  '#E9D6D9',
  '#C79BA2',
  '#4631D1',
  '#D13186',
  '#009E5F',
  '#009E5E',
  '#009E5C',
  '#136B46',
];
const IsPrivate = [
  {
    id: 1,
    item: `Private`,
  },
  {
    id: 0,
    item: `Public`,
  },
];
