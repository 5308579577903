import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { RootState } from 'typesNew';

const selectDomain = (state: RootState) => {
  if (state && state.demoProject) {
    return state.demoProject;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading,
);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectProjectDetails = createSelector(
  [selectDomain],
  (state) => state.projectDetails,
);
export const selectCreateFileData = createSelector(
  [selectDomain],
  (state) => state.createFileData,
);
export const selectCreateFolder = createSelector(
  [selectDomain],
  (state) => state.createFolder,
);
