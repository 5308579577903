import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { RootState } from '../../typesNew';

const selectDomain = (state: RootState) => {
  if (state && state.userState) {
    return state.userState;
  } else {
    return initialState;
  }
};

export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading,
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo,
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize,
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow,
);
export const selectSearch = createSelector(
  [selectDomain],
  (state) => state.search,
);
export const selectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir,
);
export const selectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn,
);
export const selectAdd = createSelector([selectDomain], (state) => state.add);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading,
);
export const selectEditLoading = createSelector(
  [selectDomain],
  (state) => state.editLoading,
);
export const selectDisableAccountModal = createSelector(
  [selectDomain],
  (state) => state.disableAccount,
);
export const selectDeleteAccountModal = createSelector(
  [selectDomain],
  (state) => state.deleteAccount,
);

export const selectBalcncelist = createSelector(
  [selectDomain],
  (state) => state.Balcncelist,
);

