import axios from 'axios';
import Api from './Api';

const loginRequest = async (data: any) => {
  return Api.post(`admin/admin-login`, data);
};
const UpdatePassword = async (data: any) => {
  return Api.put(`/admin/update-password`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const meProfileRequest = async () => {
  return Api.get(`/auth/user/me`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
//user section//
const doAddUser = async (data: any) => {
  return Api.post(`/admin/add-user`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doDeleteUser = async (_id: any) => {
  return Api.delete(`/admin/user-delete/${_id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doDisableUser = async (_id: any, data: any) => {
  return Api.put(`/admin/update-user-active-status/${_id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doGetUserList = async (filter: string) => {
  return Api.get(`/admin/user-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doGetSubscribeList = async (filter: string, data: any) => {
  return Api.post(`/order/get-user-subscription-list${filter}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};

const doGetSubscribeBillingList = async (filter: string, id: string) => {
  return Api.get(`/order/user-billing-history/${id}${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};

const doGetUserEdit = async (id: string) => {
  return Api.get(`/admin/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doUpdateUser = async (data: any) => {
  return Api.put(`/admin/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};

//Team Section -----------------------------------------------

const doAddTeam = async (data: any) => {
  return Api.post(`/admin/add-Team`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doDeleteTeam = async (_id: any) => {
  return Api.delete(`/team/user-delete/${_id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doDisableTeam = async (_id: any, data: any) => {
  return Api.put(`/team/admin-update-status/${_id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doGetTeamList = async (filter: string) => {
  return Api.get(`/team/admin-get-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doGetTeamEdit = async (id: string) => {
  return Api.get(`team/admin-get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doUpdateTeam = async (data: any) => {
  return Api.put(`team/admin-update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};

//user section
// plan section start//
const GetPlanList = async (filter: string) => {
  return Api.get(`plan/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};

const GetPlanById = async (id: string) => {
  return Api.get(`plan/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const PlanUpdate = async (data: any) => {
  return Api.put(`plan/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const DemoUpdate = async (data: any) => {
  return Api.put(`/project/update-demo-project`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const CancelSubscription = async (id: string) => {
  return Api.get(`/order/cancel/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
// plan section end //

const doGetDashboardData = async (filter: string) => {
  return Api.get(`admin/dashboard${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const doGetDashboardChartData = async (filter: string) => {
  return Api.get(`admin/dashboard-graph${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};

const doGetAmountHistoryList = async (filter: string) => {
  return Api.get(`/order/user/balance-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};

const GetDraftDataList = async () => {
  return Api.get(`/project/get-demo-project-detail`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const getImageRequest = async (data: any) => {
  return Api.post(
    `/project/signed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    },
  );
};
const uplodeImageRequest = async (
  signedUrl: any,
  data: any,
  contentType: any,
) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      'Content-Type': contentType,
    },
  });
};
// Demo Project  Details//
const DemoProjectDetails = async () => {
  return Api.get(`project/get-demo-project`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const DemoProjectList = async (id: string) => {
  return Api.get(`file/admin-folder-list/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const FileFolderSingedUrl = async (data: any) => {
  return Api.post(`file/signed-url`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const uploadFilesAndFolder = async (
  signedUrl: any,
  data: any,
  contentType: any,
  // onProgress: any,
) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      'Content-Type': contentType,
    },
    // onUploadProgress: onProgress,
  });
};
const CreateFileFolder = async (data: any) => {
  return Api.post(`file/create-demo-file`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const CreateFolder = async (data: any) => {
  return Api.post(`/file/add-folder`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const DeleteFile = async (id: string) => {
  return Api.delete(`file/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
export {
  DemoUpdate,
  uplodeImageRequest,
  getImageRequest,
  GetDraftDataList,
  doGetAmountHistoryList,
  doGetDashboardChartData,
  doGetDashboardData,
  CancelSubscription,
  doGetSubscribeBillingList,
  loginRequest,
  meProfileRequest,
  UpdatePassword,
  doGetUserList,
  doAddUser,
  doGetUserEdit,
  doUpdateUser,
  doDeleteUser,
  doDisableUser,
  doGetTeamList,
  doAddTeam,
  doGetTeamEdit,
  doUpdateTeam,
  doDeleteTeam,
  doDisableTeam,
  PlanUpdate,
  GetPlanById,
  GetPlanList,
  doGetSubscribeList,
  DemoProjectDetails,
  DemoProjectList,
  FileFolderSingedUrl,
  uploadFilesAndFolder,
  CreateFileFolder,
  CreateFolder,
  DeleteFile,
};
