export interface DataInterface {
  _id: string;
  id?: string;
  userName: string;
  filePath: string;
  projectId: string;
  parentFileId: string;
  name: string;
  color: string;
  isPrivate: boolean;
  status: FileStatusEnum;
  possibleResolution: [];
  fileType: FileTypeEnum;
  videoTimeDuration: number;
  fileData: PreviewFilesDataInterface[];
  fileCount: number;
  uploadStatus: UploadStatusEnum;
  fileVersionId: string;
  fileThumbnailPath: string;
  mimeType: string;
  commentCount: number;
  profilePic: string;
  createdAt: string;
  total: number;
  completedTotal: number;
  uploadProgress: number;
  versionCount: number;
  thumbnailFileStatus?: ThumbnailFileStatusEnum;
  fileUploadStatus?: FileUploadStatusEnum;
  height?: number;
  size?: number;
  sortField?: string;
  userId?: string;
  width?: number;
}
export interface PreviewFilesDataInterface {
  fileType: FileTypeEnum;
  fileThumbnailPath: string;
}
export enum FileUploadStatusEnum {
  IN_PROGRESS = 1,
  UPLOADED = 2,
  FAILED = 3,
}
export enum UploadStatusEnum {
  IN_QUEUE = 1,
  UPLOADING = 2,
  PROCESSING = 3,
  COMPLETED = 4,
}
export enum ThumbnailFileStatusEnum {
  IN_PROGRESS = 1,
  PROCESSED = 2,
  FAILED = 3,
}
export enum FileStatusEnum {
  NO_STATUS = 0,
  IN_REVIEW = 1,
  IN_PROGRESS = 2,
  APPROVED = 3,
}
export interface IProjectDetails {
  _id: string;
  fileId: string;
  description: string;
  projectThumbnailPath: string;
  title: string;
}
export enum FileTypeEnum {
  FOLDER = 1,
  FILE = 0,
}
export interface CreateVersionInterface {
  projectId: string;
  fileId: string;
  fileName: string;
  filePath: string;
  fileSize: string;
}
export interface CreateFileInterface {
  fileId: string;
  projectId: string;
  fileName: string;
  filePath: string;
  fileSize: string;
  isFolder: boolean;
  folderId: string;
}
export interface FolderInterface {
  projectId: string;
  fileId: string;
  name: string;
  color: string;
  isPrivate: boolean;
}
export interface DemoProjectState {
  list: DataInterface[];
  projectDetails: IProjectDetails;
  uploadQueue: any[];
  loading: boolean;
  createFileData: CreateFileInterface;
  createFolder: FolderInterface;
}

export type InitialState = DemoProjectState;
