import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  ListItem,
  Select,
  Spinner,
  Text,
  List,
  useColorModeValue,
  CloseButton,
  Table,
  Th,
  Tr,
  Td,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import { selectTogglePassword } from '../../Redux/Login/selector';
import {
  selectButtonLoading,
  selectEditLoading,
  selectFeatureForm,
  selectFeatureList,
  selectForm,
} from '../../Redux/Plan/selector';
import { toast } from 'sonner';
import { actions } from '../../Redux/Plan/slice';
import FooterButtons from 'components/FooterButtons/Index';
import ModalTemplate from 'components/ModalTemplate/Index';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlanTypeEnum } from '../../Redux/Plan/types';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface props {
  id: string;
  open: boolean;
  handleClose: any;
}

function Index(props: props) {
  const textColor = useColorModeValue('#D2D3E0', '#D2D3E0');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const textColorSecondary = 'gray.400';
  const form = useSelector(selectForm);
  const show = useSelector(selectTogglePassword);
  const buttonLoading = useSelector(selectButtonLoading);
  const loading = useSelector(selectEditLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.id) {
      dispatch(actions.doGetEdit(props.id));
    }
    return () => {};
  }, [props.id]);

  const handleFieldChange = (evt: any) => {
    const { name, value } = evt.target;

    dispatch(
      actions.updateFormValue({
        key: name,
        value:
          name == 'versioning' || name == 'deleteProject'
            ? Number(value)
            : value,
      }),
    );
    console.log(form.versioning, 'versioning');
  };
  const handleSelectChange = (name: string, evt: any) => {
    dispatch(actions.updateFormValue({ key: name, value: evt }));
  };
  const handleNumberChange = (evt: any) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: Number(value) }));
  };

  const handleFileVersionNumberChange = (evt: any) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: Number(value) }));
  };

  const handleAdd = () => {
    dispatch(
      actions.doUpdate({
        callback: () => {
          props?.handleClose();
          dispatch(actions.doGetList());
          dispatch(actions.clearFeatureForm());
          dispatch(actions.clearFeatureList());
        },
      }),
    );
  };
  useEffect(() => {
    if (props.id) {
      dispatch(actions.setEditLoading(true));
    }
    return () => {};
  }, [props.id]);
  const featureForm = useSelector(selectFeatureForm);
  const handleFeatureChange = (evt: any) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFeatureFormValue({ key: name, value: value }));
  };

  const handleAddFeature = () => {
    if (FeatureList.length <= 4) {
      if (
        featureForm?.name.length == 0 ||
        featureForm?.name == undefined ||
        featureForm?.japaneseName.length == 0 ||
        featureForm?.japaneseName == undefined
      ) {
        toast.error('Please Enter Name');
        return;
      }
      if (featureForm?.name.length == 0 || featureForm?.name == undefined) {
        toast.error('Please Enter Name');
        return;
      }
      dispatch(actions.addFeature());
      dispatch(actions.clearFeatureForm());
    } else {
      toast.error('Can not add more then 5');
    }
  };
  const FeatureList = useSelector(selectFeatureList);
  const handleDelete = (index: number) => {
    dispatch(
      actions.removeFeature({
        index: index,
      }),
    );
  };
  return (
    <>
      <ModalTemplate
        title={'Update Plan'}
        open={props?.open}
        handleClose={props?.handleClose}
        width={{ sm: '95%', md: '70%', lg: '65%', xl: '50%' }}
      >
        {loading ? (
          <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
            <Spinner color="brand.500" />
          </Box>
        ) : (
          <Box>
            <FormControl>
              <Grid
                templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                gap={2}
              >
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Name<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="text"
                    placeholder="Enter name"
                    size="md"
                    name="name"
                    value={form.name}
                    maxLength={255}
                    onChange={handleFieldChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Japanese Name<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="text"
                    placeholder="Enter japanese name"
                    size="md"
                    name="japaneseName"
                    value={form.japaneseName}
                    maxLength={255}
                    onChange={handleFieldChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Project<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="number"
                    placeholder="Enter projects"
                    size="md"
                    name="projects"
                    value={form.projects}
                    maxLength={255}
                    onChange={handleNumberChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Description<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    placeholder="Enter Description"
                    size="md"
                    name="description"
                    value={form.description}
                    maxLength={500}
                    onChange={handleFieldChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Japanese Description<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    placeholder="Enter Japanese Description"
                    size="md"
                    name="japaneseDescription"
                    value={form.japaneseDescription}
                    maxLength={500}
                    onChange={handleFieldChange}
                  />
                </GridItem>

                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Total Users<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="number"
                    placeholder="Enter users"
                    size="md"
                    name="users"
                    value={form.users}
                    maxLength={255}
                    onChange={handleNumberChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Monthly Amount ¥ &nbsp;<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    disabled={form.planType == PlanTypeEnum.FREE}
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="number"
                    placeholder="Enter monthly amount"
                    size="md"
                    name="monthlyAmount"
                    value={form.monthlyAmount}
                    onChange={handleNumberChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Yearly Amount ¥ &nbsp;<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    disabled={form.planType == PlanTypeEnum.FREE}
                    variant="auth"
                    fontSize="15"
                    type="number"
                    placeholder="Enter yearly amount"
                    size="md"
                    name="yearlyAmount"
                    value={form.yearlyAmount}
                    onChange={handleNumberChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Total Storage (GB) <Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="number"
                    placeholder="Enter storage"
                    size="md"
                    name="storage"
                    value={form.storage}
                    onChange={handleNumberChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Sort Order<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="number"
                    placeholder="Enter order"
                    size="md"
                    name="sortOrder"
                    value={form?.sortOrder}
                    onChange={handleNumberChange}
                  />
                </GridItem>

                <GridItem>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Subscription Plan<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Select
                    _hover={{
                      borderColor: '#313248',
                    }}
                    _focus={{
                      borderColor: '#313248',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    _active={{
                      borderColor: '#313248',
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    disabled
                    variant="outline"
                    size="md"
                    fontSize="15"
                    borderColor={'#313248'}
                    backgroundColor={'#1B1C20'}
                    borderRadius={'4px'}
                    name="planType"
                    color={textColor}
                    value={form?.planType}
                    sx={{
                      '> option': {
                        background: '#21232E',
                        color: '#D2D3E0',
                      },
                    }}
                  >
                    {planData.map((data: any) => (
                      <option key={data?.id} value={data?.id}>
                        {data?.item}
                      </option>
                    ))}
                  </Select>
                </GridItem>
                <GridItem>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Versions
                  </FormLabel>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      _hover={{ borderColor: '#313248' }}
                      _focus={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      _active={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      variant="auth"
                      size="md"
                      textAlign={'start'}
                      fontSize="15"
                      value={Number(form?.versioning)}
                      width={'100%'}
                      borderColor="#313248"
                      backgroundColor="#1B1C20"
                      borderRadius="4px"
                      color={textColor}
                    >
                      {trueFalseData.find(
                        (data) => data.id === Number(form?.versioning),
                      )?.item || 'Select an option'}
                    </MenuButton>
                    <MenuList
                      backgroundColor="#1B1C20"
                      borderColor="#313248"
                      width={'100%'}
                      padding={0}
                    >
                      {trueFalseData.map((data) => (
                        <MenuItem
                          key={data.id}
                          width={'100%'}
                          style={{
                            width: '100%',
                          }}
                          value={data.id}
                          onClick={() =>
                            handleSelectChange('versioning', data.id)
                          }
                          _hover={{
                            backgroundColor: '#313248',
                            color: '#D2D3E0',
                          }}
                          backgroundColor="#21232E"
                          color="#D2D3E0"
                        >
                          {data.item}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </GridItem>
                <GridItem>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    is Most Popular Plan
                  </FormLabel>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      _hover={{ borderColor: '#313248' }}
                      _focus={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      _active={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      variant="auth"
                      size="md"
                      textAlign={'start'}
                      fontSize="15"
                      value={Number(form?.mostPopular)}
                      width={'100%'}
                      borderColor="#313248"
                      backgroundColor="#1B1C20"
                      borderRadius="4px"
                      color={textColor}
                    >
                      {trueFalseData.find(
                        (data) => data.id === Number(form?.mostPopular),
                      )?.item || 'Select an option'}
                    </MenuButton>
                    <MenuList
                      backgroundColor="#1B1C20"
                      borderColor="#313248"
                      width={'100%'}
                      padding={0}
                    >
                      {trueFalseData.map((data) => (
                        <MenuItem
                          key={data.id}
                          width={'100%'}
                          style={{
                            width: '100%',
                          }}
                          value={data.id}
                          onClick={() =>
                            handleSelectChange('mostPopular', data.id)
                          }
                          _hover={{
                            backgroundColor: '#313248',
                            color: '#D2D3E0',
                          }}
                          backgroundColor="#21232E"
                          color="#D2D3E0"
                        >
                          {data.item}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </GridItem>
                <GridItem>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Project Delete
                  </FormLabel>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      _hover={{ borderColor: '#313248' }}
                      _focus={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      _active={{
                        borderColor: '#313248',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      variant="auth"
                      size="md"
                      textAlign={'start'}
                      fontSize="15"
                      value={Number(form?.deleteProject)}
                      width={'100%'}
                      borderColor="#313248"
                      backgroundColor="#1B1C20"
                      borderRadius="4px"
                      color={textColor}
                    >
                      {trueFalseData.find(
                        (data) => data.id === Number(form?.deleteProject),
                      )?.item || 'Select an option'}
                    </MenuButton>
                    <MenuList
                      backgroundColor="#1B1C20"
                      borderColor="#313248"
                      width={'100%'}
                      padding={0}
                    >
                      {trueFalseData.map((data) => (
                        <MenuItem
                          key={data.id}
                          width={'100%'}
                          style={{
                            width: '100%',
                          }}
                          value={data.id}
                          onClick={() =>
                            handleSelectChange('deleteProject', data.id)
                          }
                          _hover={{
                            backgroundColor: '#313248',
                            color: '#D2D3E0',
                          }}
                          backgroundColor="#21232E"
                          color="#D2D3E0"
                        >
                          {data.item}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    File Max Ratio<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="number"
                    placeholder="Enter File Max Ratio"
                    size="md"
                    name="fileMaxRatio"
                    value={form?.fileMaxRatio}
                    onChange={handleFileVersionNumberChange}
                  />
                </GridItem>

                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Plan Features
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="text"
                    placeholder="Write Plan Features"
                    size="md"
                    name="name"
                    value={featureForm.name}
                    onChange={handleFeatureChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Plan japanese Features
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="text"
                    placeholder="Write Plan Features"
                    size="md"
                    name="japaneseName"
                    value={featureForm.japaneseName}
                    onChange={handleFeatureChange}
                  />
                </GridItem>
                <GridItem>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="400"
                    w={{ sm: '100%', md: 'auto' }}
                    marginTop={7}
                    padding={'6px 16px'}
                    _focus={{
                      borderColor: '#EEEFFC',
                    }}
                    tabIndex={0}
                    onClick={handleAddFeature}
                  >
                    Add
                  </Button>
                </GridItem>
              </Grid>
              {FeatureList.length > 0 && (
                <Box
                  borderRadius="md"
                  borderLeft="1px solid white"
                  borderRight="1px solid white"
                  boxShadow="md"
                  marginTop={10}
                  overflowX="auto"
                >
                  <Table>
                    <Tr color={'white'}>
                      <Th
                        textAlign="center"
                        bg="#B99E68"
                        color="white"
                        fontWeight="bold"
                      >
                        S.No.
                      </Th>
                      <Th
                        textAlign="center"
                        bg="#B99E68"
                        color="white"
                        fontWeight="bold"
                      >
                        Feature
                      </Th>
                      <Th
                        textAlign="center"
                        bg="#B99E68"
                        color="white"
                        fontWeight="bold"
                      >
                        Japanese Feature
                      </Th>
                      <Th
                        textAlign="center"
                        bg="#B99E68"
                        color="white"
                        fontWeight="bold"
                      >
                        Delete
                      </Th>
                    </Tr>
                    {FeatureList.map((data, index) => (
                      <Tr key={index} color={'white'}>
                        <Td textAlign={'center'}>{index + 1}</Td>
                        <Td textAlign={'center'}>{data.name}</Td>
                        <Td textAlign={'center'}>{data.japaneseName}</Td>
                        <Td justifyContent="center" alignItems="center">
                          <CloseButton
                            onClick={() => handleDelete(index)} // Pass the index to handle delete action
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Table>
                </Box>
              )}
            </FormControl>
          </Box>
        )}
        <FooterButtons
          title="Save Changes"
          loading={buttonLoading}
          handleCancelButton={props?.handleClose}
          handleClick={handleAdd}
        />
      </ModalTemplate>
    </>
  );
}

export default Index;
const trueFalseData = [
  {
    id: 1,
    item: `Yes`,
  },
  {
    id: 0,
    item: `No`,
  },
];

const planData = [
  {
    id: PlanTypeEnum.FREE,
    item: `Free`,
  },
  {
    id: PlanTypeEnum.BASIC,
    item: `Basic`,
  },
  {
    id: PlanTypeEnum.PROFESSIONAL,
    item: `Professional`,
  },
];
const optionsStyle = {
  backgroundColor: '#21232E',
  color: '#D2D3E0',
  '.options: hover': {
    backgroundColor: 'rgb(49, 50, 72) !important',
  },
  '.options:placeholder': {
    backgroundColor: '#21232E',
    color: '#D2D3E0',
  },
};
