'use client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { TeamInterface } from './types';
import { set } from 'lodash';
import { StatusTypeEnum } from '../../Redux/User/types';

export const useClinicSlice = createSlice({
  name: 'teamState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setEditLoading: (state, action: PayloadAction<boolean>) => {
      state.editLoading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    toggleTeamUpdate: (state, action: PayloadAction<boolean>) => {
      state.teamUpdate = action.payload;
    },
    toggleDisableAccountModal: (state, action: PayloadAction<boolean>) => {
      state.disableAccount = action.payload;
    },
    toggleDeleteAccountModal: (state, action: PayloadAction<boolean>) => {
      state.deleteAccount = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>,
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearFrom: (state) => {
      state.form._id = '';
      state.form.email = '';
      state.form.name = '';
      state.form.password = '';
      state.form.profilePic = '';
      // state.form.totalProject = 0;
      // state.form.totalTeam = 0;
      // state.form.plan = PlanTypeEnum.FREE;
      state.form.isActive = StatusTypeEnum.ACTIVE;
      state.form.actions = false;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doDelete: (
      state,
      action: PayloadAction<{ id: string; callback: any }>,
    ) => {},
    doDisableAccount: (
      state,
      action: PayloadAction<{ id: string; status: number; callback: any }>,
    ) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setList: (state, action: PayloadAction<Array<TeamInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<TeamInterface>) => {
      state.form = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
