import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import {
  doDeleteUser,
  doDisableUser,
  GetPlanList,
  GetPlanById,
  PlanUpdate,
  GetDraftDataList,
  getImageRequest,
  uplodeImageRequest,
  DemoUpdate,
} from '../../utils/request';
import {
  selectDraftForm,
  selectFeatureList,
  selectForm,
  selectPageNo,
  selectPageSize,
  selectSearch,
  selectSortColumn,
  selectSortDir,
} from './selector';
import CatchBlockFunction from 'components/hooks/CatchError';
import { DataInterface, ProjectDataForm, featureFormInterface } from './types';
import { toast } from 'sonner';

export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetPlanList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`,
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0,
      ),
    );
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    yield put(actions.setEditLoading(true));
    const response: AxiosResponse = yield call(GetPlanById, action.payload);
    yield put(actions.setEditLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    yield put(actions.setEditLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: DataInterface = yield select(selectForm);
  const list: featureFormInterface[] = yield select(selectFeatureList);
  const data = {
    ...form,
    featureList: list,
  };
  if (form.name.length == 0) {
    toast.error('Please enter name');
    return;
  }
  if (720 > form.fileMaxRatio) {
    toast.error('You Can Not Enter Less Then 720');
    return;
  }

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(PlanUpdate, data);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doDisableAccountRequest(action: {
  payload: { id: string; status: number; callback: any };
}) {
  yield delay(500);

  yield put(actions.setButtonLoading(true));
  try {
    let data = {
      isActive: action.payload.status,
    };
    const response: AxiosResponse = yield call(
      doDisableUser,
      action.payload.id,
      data,
    );
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield put(actions.setButtonLoading(false));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetDemoProjectListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(GetDraftDataList);

    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setDraftData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      getImageRequest,
      action.payload.data,
    );
    if (!response.status) {
      toast.error(response.data.message);
      return;
    }

    yield call(
      action?.payload?.callback(
        response.data.data.signedUrl,
        response.data.data.fileNameWithPrefix,
      ),
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: {
  payload: { data: any; signedUrl: string; result: any; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uplodeImageRequest,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result,
    );
    if (!response.status) {
      toast.error(response.data.message);
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doUpdateDemoRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: ProjectDataForm = yield select(selectDraftForm);
  const data = {
    title: form.title,
    description: form.description,
    fileData: form.fileData,
  };
  try {
    const response: AxiosResponse = yield call(DemoUpdate, data);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* PlanStateRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doUpdateDemo, doUpdateDemoRequest);
  yield takeLatest(actions.doDisableAccount, doDisableAccountRequest);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
  yield takeLatest(actions.doGetDemoProjectList, doGetDemoProjectListRequest);
}
