'use client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { PlanTypeEnum, DataInterface, ProjectData, typeEnum } from './types';
import { set } from 'lodash';
import { StatusTypeEnum } from '../../Redux/User/types';

export const useClinicSlice = createSlice({
  name: 'planState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setEditLoading: (state, action: PayloadAction<boolean>) => {
      state.editLoading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    toggleAdd: (state, action: PayloadAction<boolean>) => {
      state.add = action.payload;
    },
    toggleDisableAccountModal: (state, action: PayloadAction<boolean>) => {
      state.disableAccount = action.payload;
    },
    toggleDraftForm: (state) => {
      state.draftForm._id = state.draftData._id;
      state.draftForm.description = state.draftData.description;
      state.draftForm.fileData = state.draftData.fileData;
      state.draftForm.title = state.draftData.title;
      state.draftForm.fileDataForm.fileName = '';
      state.draftForm.fileDataForm.folderColor = '#EEEFFC';
      state.draftForm.fileDataForm.imagePath = '';
      state.draftForm.fileDataForm.imageSize = 0;
      state.draftForm.fileDataForm.isPrivate = false;
      state.draftForm.fileDataForm.type = typeEnum.ALL;
    },
    removeDraftForm: (state, action: PayloadAction<{ index: any }>) => {
      state.draftForm.fileData.splice(action.payload.index, 1);
    },
    addDraftForm: (state) => {
      state.draftForm.fileData.push(state.draftForm.fileDataForm);
    },
    ClearDraftForm: (state) => {
      state.draftForm.fileDataForm.fileName = '';
      state.draftForm.fileDataForm.folderColor = '#EEEFFC';
      state.draftForm.fileDataForm.imagePath = '';
      state.draftForm.fileDataForm.imageSize = 0;
      state.draftForm.fileDataForm.isPrivate = false;
      state.draftForm.fileDataForm.type = typeEnum.ALL;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>,
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearFrom: (state) => {
      state.form._id = '';
      state.form.name = '';
      state.form.projects = 0;
      state.form.users = 0;
      state.form.storage = 0;
      state.form.monthlyAmount = 0;
      state.form.yearlyAmount = 0;
      state.form.reviewers = 0;
      state.form.sortOrder = 0;
      state.form.fileMaxRatio = 0;
      state.form.versioning = false;
      state.form.deleteProject = false;
      state.form.planType = PlanTypeEnum.FREE;
      state.form.createdAt = '';
      state.form.updatedAt = '';
      state.form.japaneseName = '';
      state.form.japaneseFeatureList = [];
      state.form.featureList = [];
      state.form.isActive = StatusTypeEnum.ACTIVE;
    },
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>,
    ) => {},
    UploadeImage: (
      state,
      action: PayloadAction<{
        data: any;
        signedUrl: string;
        result: any;
        callback: any;
      }>,
    ) => {},

    doGetList: (state) => {},

    doGetDemoProjectList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},

    doDisableAccount: (
      state,
      action: PayloadAction<{ id: string; status: number; callback: any }>,
    ) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doUpdateDemo: (
      state,
      action: PayloadAction<{ callback: () => void }>,
    ) => {},
    setList: (state, action: PayloadAction<Array<DataInterface>>) => {
      state.list = action.payload;
    },

    setDraftData: (state, action: PayloadAction<ProjectData>) => {
      state.draftData = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<DataInterface>) => {
      state.form = action.payload;
      state.featureList = action.payload?.featureList;
    },

    clearFeatureForm: (state) => {
      state.featureForm._id = '';
      state.featureForm.name = '';
      state.featureForm.japaneseName = '';
    },
    clearFeatureList: (state) => {
      state.featureList = [];
     
    },
    addFeature: (state) => {
      state.featureList.push(state.featureForm);
    },
   
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    removeFeature: (state, action: PayloadAction<{ index: any }>) => {
      state.featureList.splice(action.payload.index, 1);
    },
   
    updateFeatureFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>,
    ) => {
      set(state, `featureForm.${action.payload.key}`, action.payload.value);
    },

    // draft Work

    updateDraftFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>,
    ) => {
      set(state, `draftForm.${action.payload.key}`, action.payload.value);
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
