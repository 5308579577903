import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import CatchBlockFunction from 'components/hooks/CatchError';
import { actions } from './slice';
import {
  CreateFileFolder,
  CreateFolder,
  DeleteFile,
  DemoProjectDetails,
  DemoProjectList,
  FileFolderSingedUrl,
  uploadFilesAndFolder,
} from 'utils/request';
import { toast } from 'sonner';
import {
  CreateFileInterface,
  CreateVersionInterface,
  FolderInterface,
} from './types';
import { selectCreateFileData, selectCreateFolder } from './selector';

export function* doGetFolderFileListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  if (
    action.payload.id?.length > 0 &&
    action.payload.id != undefined &&
    action.payload.id != null
  ) {
    try {
      yield put(actions.setLoading(true));
      const response: AxiosResponse = yield call(
        DemoProjectList,
        action.payload.id,
      );
      yield put(actions.setLoading(false));
      if (response && !response.data) {
        toast.error(response.data.message);
        return;
      }
      yield put(actions.setFileFolderList(response.data.data.results));
      yield call(action?.payload?.callback());
    } catch (error: any) {
      yield put(actions.setLoading(false));
      CatchBlockFunction(error);
    }
  }
}
export function* doGetDemoProjectDetailsRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(DemoProjectDetails);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setProjectDetails(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: {
  payload: {
    fileName: string;
    projectId: string;
    fileId: string;
    callback: any;
  };
}) {
  try {
    const response: AxiosResponse = yield call(
      FileFolderSingedUrl,
      action.payload,
    );
    if (!response.status) {
      toast.error(response.data.message);
    }
    yield call(action?.payload?.callback(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadFileFolderRequest(action: {
  payload: { data: any; signedUrl: string; result: any; callback: any };
}) {
  try {
    const response: AxiosResponse = yield call(
      uploadFilesAndFolder,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result,
    );
    if (!response.status) {
      toast.error(response.data.message);
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doCreateFileRequest(action: {
  payload: { data: CreateFileInterface; callback: any };
}) {
  try {
    const response: AxiosResponse = yield call(
      CreateFileFolder,
      action.payload.data,
    );
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

//folder create
export function* doAddFolderRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: FolderInterface = yield select(selectCreateFolder);
  if (form.name.length === 0) {
    toast.error('please fill name');
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(CreateFolder, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDeleteFileAndFolderRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(DeleteFile, action.payload.id);

    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* DemoProjectRepoSaga() {
  yield takeLatest(
    actions.doGetDemoProjectDetails,
    doGetDemoProjectDetailsRequest,
  );
  yield takeLatest(actions.doGetFolderFileList, doGetFolderFileListRequest);
  yield takeLatest(actions.doAddFile, FileUploadRequest);
  yield takeLatest(actions.doUploadFileFolder, UploadFileFolderRequest);
  yield takeLatest(actions.doCreateFileFolder, doCreateFileRequest);

  yield takeLatest(actions.doCreateFolder, doAddFolderRequest);
  yield takeLatest(actions.doDeleteFileAndFolder, doDeleteFileAndFolderRequest);
}
