import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import {
  doGetUserList,
  doAddUser,
  doGetUserEdit,
  doUpdateUser,
  doDeleteUser,
  doDisableUser,
  doGetAmountHistoryList,
} from '../../utils/request';
import {
  selectForm,
  selectPageNo,
  selectPageSize,
  selectSearch,
  selectSortColumn,
  selectSortDir,
} from './selector';
import CatchBlockFunction from 'components/hooks/CatchError';
import { useToast } from '@chakra-ui/react';
import { UserInterface } from './types';
import { toast } from 'sonner';
import { passwordRegex } from 'utils/constants/Password';
import { emailRegex } from 'utils/constants/Email';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: UserInterface = yield select(selectForm);

  if (form.name.length == 0) {
    toast.error('Please enter name');
    return;
  }
  if (form.email.length == 0) {
    toast.error('Please enter email');
    return;
  }
  if (form.email.length > 0) {
    if (!emailRegex.test(form.email)) {
      toast.error('Please enter valid email');
      return;
    }
  }
  if (form.password.length == 0) {
    toast.error('Please enter password');
    return;
  }
  if (form.password.length > 0) {
    if (!passwordRegex.test(form.password)) {
      toast.error('Please enter valid password');
      return;
    }
  }
  let data = {
    name: form.name,
    email: form.email,
    password: btoa(form.password),
    plan: form.plan,
  };
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddUser, data);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield put(actions.setButtonLoading(false));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetUserList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`,
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0,
      ),
    );
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    yield put(actions.setEditLoading(true));
    const response: AxiosResponse = yield call(doGetUserEdit, action.payload);
    yield put(actions.setEditLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    yield put(actions.setEditLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: UserInterface = yield select(selectForm);
  if (form.name.length == 0) {
    toast.error('Please enter name');
    return;
  }

  let data = {
    _id: form._id,
    name: form.name,
    email: form.email,
    plan: form.plan,
    isActive: form.isActive,
  };
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateUser, data);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDeleteRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(doDeleteUser, action.payload.id);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield put(actions.setButtonLoading(false));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDisableAccountRequest(action: {
  payload: { id: string; status: number; callback: any };
}) {
  yield delay(500);

  yield put(actions.setButtonLoading(true));
  try {
    let data = {
      isActive: action.payload.status,
    };
    const response: AxiosResponse = yield call(
      doDisableUser,
      action.payload.id,
      data,
    );
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield put(actions.setButtonLoading(false));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetAmountHistoryListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetAmountHistoryList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`,
    );
    
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0,
      ),
    );
    yield put(actions.setBalcncelist(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* UserStateRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetAmountHistoryList, doGetAmountHistoryListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doDisableAccount, doDisableAccountRequest);
  yield takeLatest(actions.doDelete, doDeleteRequest);
}
