export interface SubscribeInterface {
  _id: string;
  name: string;
  email: string;
  planType: PlanTypeEnum;
  planAmount: string;
  planExpiryDate: string;
  currency: string;
  isActive: SubscribeStatusTypeEnum;
  createdAt: string;
  updatedAt: string;
  status: OrderStatusEnum;
  subscriptionCanceled: boolean;
  subscriptionType: SubscriptionTypeEnum;
  planName: string;
}
export enum SubscriptionTypeEnum {
  MONTHLY = 1,
  YEARLY = 2,
}
export enum SubscribeStatusTypeEnum {
  ACTIVE = 1,
  INACTIVE = 2,
}
export enum PlanTypeEnum {
  FREE = 1,
  BASIC = 2,
  PROFESSIONAL = 3,
}
export enum PaymentStatusEnum {
  SUCCESS = 1,
  FAILED = 2,
  CANCELLED = 3,
}
export enum OrderStatusEnum {
  PENDING = 1,
  SUCCESS = 2,
  FAILED = 3,
  CANCELLED = 4,
}
export interface listBillingInterface {
  _id: string;
  transactionDate: string;
  invoiceNumber: string;
  status: number;
  amount: number;
  currency: string;
  createdAt: string;
  updatedAt: string;
}
export interface SubscribeState {
  form: SubscribeInterface;
  loading: boolean;
  editLoading: boolean;
  list: SubscribeInterface[];
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  add: boolean;
  buttonLoading: boolean;
  disableAccount: boolean;
  deleteAccount: boolean;
  loadingBilling: boolean;
  listBilling: listBillingInterface[];
  pageNoBilling: number;
  pageSizeBilling: number;
  totalRowBilling: number;
  planFilter : PlanTypeEnum;
  planFilterArray :any
}

export type InitialSubscribeState = SubscribeState;
