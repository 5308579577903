import { AuthState } from ".";

export const initialState: AuthState = {
  form: {
    email: "",
    password: "",
  },
  password: {
    password: "",
    confirmPassword: "",
  },
  userData: {
    name: "",
    // profilePic: "",
    email: "",
    _id: "",
  },
  loading: false,
  token: "",
  isLogin: false,
  isToken: "",
  isPathname: "",
  togglePassword: false,
  toggleConfirmPassword: false
};
